import React from "react";
import Style from './Container.module.css'
import button__img from '../../../images/header/header__button.webp'
import Slider from "react-slick";
import {images1BHK, images2BHK, imagesPenthouse, imagesStudio} from "../../Shop/Apartments/imagesApartment";

const Container = ({priceInMANA, name, price, text, textTitle}) => {

    const image = () => {
        if (name === 'Studio') {
            return imagesStudio
        } else if (name === '1BHK') {
            return images1BHK
        } else if (name === '2BHK') {
            return images2BHK
        } else if (name === 'Penthouse') {
            return imagesPenthouse
        }
    }
    const images = image()

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <div className={Style.container}>
            <Slider {...settings} className={Style.container__img}>
                {images.map((image, index) => (
                    <div key={index} className={Style.img__box}>
                        <img src={image.url} alt={`Slide`} loading={'lazy'}/>
                    </div>
                ))}
            </Slider>
            <div className={Style.box}>
                <div className={Style.title__box}>
                    <h2 className={Style.title}><span>{name}</span></h2>
                </div>
                <div className={Style.text__box}>
                    <p className={Style.subtitle}>About</p>
                    <p className={Style.subtitle__text_title}>{textTitle}
                    </p>
                    <div className={Style.subtitle__text}>{text}</div>
                </div>
                <div className={Style.button__box}>
                    <div className={Style.price__box}>
                        <span className={Style.price}>PRICE</span>
                        <div className={Style.price__container}>
                            <span className={Style.price__number}>${price}</span>
                            <span className={Style.price__info}>~ aprox. ${Math.floor(priceInMANA)} MANA</span>
                        </div>
                    </div>
                    <a href={'#mint'} className={Style.button}>
                        <img src={button__img} className={Style.button__img} alt={'button img'}/>BUY NOW
                        <svg className={Style.button__icon} width="25" height="16" viewBox="0 0 25 16" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M22.2444 13.2954L12.5001 3.27268L2.75575 13.2954" stroke="#121212" strokeWidth="5"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                        </svg></a>
                </div>
            </div>
        </div>
    )
}

export default Container
