import React from "react";
import Style from './Apartment.module.css'

const Apartment = ({item, index, selectedApartment, name, dataApartment}) => {

    return (
        <div className={`${Style.apartment} ${item.owner !== '' ? Style.true : Style.false} ${Style[`apartment__${index}`]}`}>
            <div className={Style.info}>
                <span className={ `${Style.name} ${Style[`name__${index}`]} ${item.owner !== '' ? Style.name__color : ''}`}>{name}</span>
                <span className={Style.price}>{item.flat}</span>
            </div>
            <div onClick={() => item.owner !== '' ? null : dataApartment(item, item.flat, name)} className={item.owner !== '' ? `${Style.occupied} ${Style[`occupied__${index}`]}`
                : `${Style.buy} ${selectedApartment === item.flat && Style.buy__color} ${Style[`buy__${index}`]}`}>{item.owner !== '' ? 'Occupied' : 'buy'}</div>
        </div>
    )
}

export default Apartment
