import React, {useEffect, useState} from "react";
import Style from './Banner.module.css'
import {motion} from "framer-motion";
import Social from "./Social/Social";
import icon from '../../images/header/header__button.svg'
import YouTube from "react-youtube";

const Banner = () => {
    const videoId = 'U8IMsaJTt-A'; // Замените на ID своего видео на YouTube
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlay = () => {
        setIsPlaying(true);
    };

    const handlePause = () => {
        setIsPlaying(false);
    };

    const handleEnd = (event) => {
        event.target.seekTo(0);
    };

    const [showYouTube, setShowYouTube] = useState(false);

    useEffect(() => {
      const handleLoad = () => {
        setShowYouTube(true);
      };
  
      window.addEventListener('load', handleLoad);
  
      return () => {
        window.removeEventListener('load', handleLoad);
      };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!isPlaying) {
                clearInterval(interval);
            }
        }, 3000);

        return () => {
            clearInterval(interval);
        };
    }, [isPlaying]);

    const Animation = {
        hidden: {
            x: 200,
            opacity: 0,
        },
        visible: {
            x: 0,
            opacity: 1,
            transition: { duration: 1 }
        }
    }

    return (
        <section className={Style.banner}>
            <div className={Style.banner__container}>
                <Social flex={'column'}/>
                <motion.div
                    initial={'hidden'}
                    whileInView={'visible'}
                    variants={Animation} className={Style.info__container}>
                    <div className={Style.content_box}>
                        <div className={Style.top_box}>
                            <h1 className={Style.top_bigTitle}>Social Simulator</h1>
                            <div className={Style.top_boxInner}>
                                <h2 className={Style.top_title}>Web3, No-Code</h2>
                                <h2 className={Style.top_title}>Apartments & Spaces</h2>
                                <span className={Style.top_subtitle}>in</span>
                            </div>
                            <div className={Style.container_inner}>
                                <img className={Style.img_inner} loading="lazy" src={icon} alt={'logo'}/>
                                <span className={Style.text_inner}>Decentraland</span>
                            </div>
                        </div>
                        <span className={Style.border}></span>
                        <h3 className={Style.expirience}>First residence experience in METAVerse </h3>
                    </div>
                    <div className={Style.button__container}>
                        <a href={'https://play.decentraland.org/?NETWORK=mainnet&position=117,-25'}
                           className={Style.button__learn}>Room Tour</a>
                        <a href={'#mint'} className={Style.button__mint}><span
                            className={Style.button__text}>BUY</span></a>
                    </div>
                </motion.div>
            </div>
            <svg className={Style.wave} height="49" viewBox="0 0 2920 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1405.81 12.7123C928.922 -7.62736 206.567 4.23742 -95 12.7123V49H3413C3413 0 2880.99 -8.12155e-06 2669.46 0C2428.64 8.32076 1882.7 33.0519 1405.81 12.7123Z" fill="#121212"/>
            </svg>
            <div className={Style.youtube_box}>
                {showYouTube && (
                        <YouTube
                            className={Style.youtube}
                            videoId={videoId}
                            onPlay={handlePlay}
                            onPause={handlePause}
                            onEnd={handleEnd}
                            opts={{
                                playerVars: {
                                    autoplay: 1,
                                    controls: 0,
                                    loop: 0,
                                    mute: 1,
                                    playsinline: 0,
                                },
                            }}
                            containerClassName="youtube-video"
                        />
                )}
            </div>
        </section>
    )
}

export default Banner
