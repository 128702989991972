import React from "react";
import {motion} from "framer-motion";
import Style from './Logo.module.css'
import logo from '../../images/Logo.svg'

const Logo = ({text}) => {

    const Animation = {
        hidden: {
            y: -50,
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: {duration: 1}
        }
    }

    return (
        <motion.div
            initial={'hidden'}
            whileInView={'visible'}
            viewport={{ once: true }}
            variants={Animation}
            className={Style.logo}>
            <img src={logo} loading="lazy" className={Style.logo__img} alt={'logo'}/>
            <h1 className={Style.logo__title}>{text}</h1>
        </motion.div>
    )
}

export default Logo
