import React from "react";
import Style from './Up.module.css'

const Up = () => {
    const handleScrollToTop = () => {
        window.scrollTo({top: 0, behavior: "smooth"});
    };


    return (
        <button type={'button'} className={Style.up} onClick={handleScrollToTop}>
            <svg className={Style.img} width="25" height="16" viewBox="0 0 25 16" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path d="M22.2444 13.2954L12.5001 3.27268L2.75575 13.2954" stroke="#121212" strokeWidth="5"
                      strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <h4 className={Style.text}>Up</h4>
        </button>
    )
}

export default Up
