


export function nameApartment(selectedCard, index) {
    if (selectedCard  <= 89 ) {
        switch (index) {
            case 0:
                return '2BHK'
            case 1:
                return '2BHK'
            case 3:
                return '2BHK'
            case 5:
                return '2BHK'
            case 18:
                return '2BHK'
            case 20:
                return '2BHK'
            case 22:
                return '2BHK'
            case 24:
                return '2BHK'
            case 25:
                return '2BHK'
            case 26:
                return '2BHK'
            case 28:
                return '2BHK'
            case 30:
                return '2BHK'
            case 43:
                return '2BHK'
            case 45:
                return '2BHK'
            case 47:
                return '2BHK'
            case 49:
                return '2BHK'
            case 40:
                return '1BHK'
            case 41:
                return '1BHK'
            case 39:
                return '1BHK'
            case 38:
                return '1BHK'
            case 36:
                return '1BHK'
            case 35:
                return '1BHK'
            case 34:
                return '1BHK'
            case 32:
                return '1BHK'
            case 7:
                return '1BHK'
            case 9:
                return '1BHK'
            case 15:
                return '1BHK'
            case 16:
                return '1BHK'
            case 10:
                return '1BHK'
            case 11:
                return '1BHK'
            case 13:
                return '1BHK'
            case 14:
                return '1BHK'
            default:
                return 'Studio'
        }
    }
    if (selectedCard > 89 ) {
        switch (index) {
            case 2:
               return 'Studio'
            case 4:
                return 'Studio'
            case 6:
                return 'Studio'
            case 8:
                return 'Studio'
            case 12:
                return 'Studio'
            case 17:
                return 'Studio'
            case 19:
                return 'Studio'
            case 21:
                return 'Studio'
            case 23:
                return 'Studio'
            case 27:
                return 'Studio'
            case 29:
                return 'Studio'
            case 31:
                return 'Studio'
            case 33:
                return 'Studio'
            case 37:
                return 'Studio'
            case 42:
                return 'Studio'
            case 44:
                return 'Studio'
            case 46:
                return 'Studio'
            case 48:
                return 'Studio'
            default:
           return 'Penthouse'
        }
    }

    return nameApartment;
}
