import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Style from "./Apartments.module.css";
import view from "../../../images/Shop/view.svg";
import wallet from "../../../images/Shop/wallet1.svg";
import button from "../../../images/AboutUs/AboutUs__button.svg";
import button2 from "../../../images/AboutUs/AboutUs__button2.svg";
import icon from "../../../images/header/header__button.webp";
import iconT from "../../../images/header/button__header_T.webp";
import {
  images1BHK,
  images2BHK,
  imagesPenthouse,
  imagesStudio,
} from "./imagesApartment";
import Preloader from "../../Preloaders/Preloaders";
import SwitchWallet from "../../SwitchWallet/SwitchWallet";
import cardLeft from "../../../images/AboutUs/card-left.svg";
import cardRight from "../../../images/AboutUs/card-right.svg";


const Apartments = ({
  apartment,
  selectedSwitch,
  nameApartment,
  selectedFloor,
  onSwitch,
  handleToggle,
  priceInMANA,
  priceInUSDT,
  account,
  activateBrowserWallet,
  allowanceMana,
  allowanceUSDT,
  approveMANA,
  approveUSDT,
  mintMANA,
  mintUSDT,
}) => {
  const [selectedImage, setSelectedImage] = useState([]);

  const image = () => {
    if (nameApartment === "Studio") {
      return imagesStudio;
    } else if (nameApartment === "1BHK") {
      return images1BHK;
    } else if (nameApartment === "2BHK") {
      return images2BHK;
    } else if (nameApartment === "Penthouse") {
      return imagesPenthouse;
    }
  };

  const abouts = () => {
    if (nameApartment === "Studio") {
      return "Cozy Studio placed from 0 to 99 floor on both sectors. There are 18 studio Apartments on each floor";
    } else if (nameApartment === "1BHK") {
      return "Comfortable 1BHK Apartment placed from 0 to 89 floor on both sectors. There are 16 1BHK Apartments on each floor";
    } else if (nameApartment === "2BHK") {
      return "Spacious 2BHK Apartment placed from 0 to 89 floor on both sectors. There are 16 2BHK Apartments on each floor";
    } else if (nameApartment === "Penthouse") {
      return "Luxury two-story Penthouse placed from 90 to 99 floor on both sectors. There are 32 Penthouse Apartments on each floor";
    }
  };
  const about = abouts();
  const images = image();
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button {...props} className={Style.button} type="button">
      <img
        className={Style.button}
        loading="lazy"
        src={button2}
        alt={"button"}
      />
    </button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button {...props} className={Style.button} type="button">
      <img
        className={Style.button}
        loading="lazy"
        src={button}
        alt={"button"}
      />
    </button>
  );

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />
  };

  if (!images) {
    return <Preloader />;
  }
  return (
    <section className={Style.apartment}>
      <div className={Style.left__box}>
        <div className={Style.left__box_top}>
          <h2 className={Style.title}>{nameApartment}</h2>
          <span className={Style.info}>
            Sector {selectedSwitch ? "B" : "A"} | Floor {selectedFloor - 1} | №
            {apartment.flat}
          </span>
          <div className={Style.freely__box}>
            <span className={Style.border}></span>
            <span className={Style.freely}>Freely</span>
            <span className={Style.border2}></span>
          </div>
          <div className={Style.text__box}>
            <p className={Style.subtitle}>About</p>
            <p className={Style.text}>{about}</p>
          </div>
          <a href={'https://play.decentraland.org/?NETWORK=mainnet&position=117,-25'} className={Style.button__view}>
            <img className={Style.view} src={view} alt={"icon"} />
            Room Tour
          </a>
        </div>
        <div className={Style.price__box}>
          <div className={Style.price__top_box}>
            <h3 className={Style.price__title}>PRICE</h3>
            <div className={Style.container}>
              <span className={Style.price__price}>${priceInUSDT}</span>
              <span
                className={
                  onSwitch ? Style.price__text_null : Style.price__text
                }
              >
                ~ aprox.
                <br />
                {Math.floor(priceInMANA)} MANA
              </span>
            </div>
          </div>
          {!account ? (
            <button
              className={Style.buttonWallet}
              onClick={() => activateBrowserWallet()}
              type={"button"}
            >
              <img
                className={Style.img__wallet}
                loading="lazy"
                src={wallet}
                alt={"img"}
              />
              connect wallet
            </button>
          ) : onSwitch ? (
            <div className={Style.button__box}>
              <button
                onClick={() => approveUSDT()}
                className={`${Style.aprove__button} ${
                  allowanceUSDT ? Style.aprove__button_none : ""
                }`}
              >
                <img className={Style.icon} src={iconT} alt={"icon"} />
                APPROVE
              </button>
              <button
                onClick={() => mintUSDT(apartment.flat)}
                className={`${Style.price__button} ${
                  allowanceUSDT ? "" : Style.disabled
                }`}
                disabled={!allowanceUSDT}
              >
                <img
                  className={Style.icon}
                  loading="lazy"
                  src={iconT}
                  alt={"icon"}
                />
                BUY NOW
              </button>
            </div>
          ) : (
            <div className={Style.button__box}>
              <button
                onClick={() => approveMANA()}
                className={`${Style.aprove__button} ${
                  allowanceMana ? Style.aprove__button_none : ""
                }`}
              >
                {" "}
                <img className={Style.icon} src={icon} alt={"icon"} />
                APROVE
              </button>
              <button
                onClick={() => mintMANA(apartment.flat)}
                className={`${Style.price__button} ${
                  allowanceMana ? "" : Style.disabled
                }`}
                disabled={!allowanceMana}
              >
                <img
                  className={Style.icon}
                  loading="lazy"
                  src={icon}
                  alt={"icon"}
                />
                BUY NOW
              </button>
            </div>
          )}
          <SwitchWallet handleToggle={handleToggle} onSwitch={onSwitch} />
        </div>
      </div>
      <div className={Style.right_box}>
        <div className={Style.first_box}>
            <Slider {...settings} className={Style.swiper_box}>
            {images.map((image, index) => (
                    <div key={index} className={Style.img__box}>
                        <img src={image.url} alt={`Slide`} loading={'lazy'}/>
                    </div>
                ))}
            </Slider>
        </div>
        <h1 className={Style.right__box__title}>When you purchase an MRT apartment, you receive the following</h1>

        <div className={Style.second_box}>
            <div className={Style.content_box}>
                <div className={Style.content_subBox}> <img src={cardLeft} alt="cardLeft" /> <p>The apartment itself with an NFT key for exclusive access.</p></div>
                <div className={Style.content_subBox}> <img src={cardLeft} alt="cardLeft" /> <p>A free furniture editor that comes with 700+ FREE items, including furniture, walls, ceilings, and floors, allowing you to customize your space.</p></div>
                <div className={Style.content_subBox}> <img src={cardLeft} alt="cardLeft" /> <p>A free smart item, the Boombox, enhances your in-apartment experience.</p></div>
                <div className={Style.content_subBox}> <img src={cardLeft} alt="cardLeft" /> <p>Free smart-items custom paintings to personalize your space further.</p></div>
            </div>
            <div className={Style.content_box}>
                <div className={Style.content_subBox}> <img src={cardLeft} alt="cardLeft" /> <p>Free public-mode access feature that you can activate as per your needs.</p></div>
                <div className={Style.content_subBox}> <img src={cardLeft} alt="cardLeft" /> <p>Free pin-code access feature that adds an additional layer of security.</p></div>
                <div className={Style.content_subBox}> <img src={cardLeft} alt="cardLeft" /> <p>The first 200 users will also receive legendary slippers and a branded robe wearable as a special gift.</p></div>
                <div className={Style.content_subBox}> <img src={cardLeft} alt="cardLeft" /> <p>For additional smart items and upgrades, you can make purchases from our shop.</p></div>
            </div>

        </div>
      </div>
    </section>
  );
};

export default Apartments;
