import React, { useEffect, useState } from "react";import { motion } from "framer-motion";
import Style from "./AboutUs.module.css";
import Logo from "../Logo/Logo";
import YouTubeVideo from "./YouTube/YouTube";
import lineLeft from "../../images/AboutUs/line-left.svg";
import lineRight from "../../images/AboutUs/line-right.svg";
import lineLeftBig from "../../images/AboutUs/lleftBig.svg";
import lineRightBig from "../../images/AboutUs/lrightBig.svg";
import cardLeft from "../../images/AboutUs/card-left.svg";
import cardRight from "../../images/AboutUs/card-right.svg";

const AboutUs = () => {
  const Animation = {
    hidden: {
      x: -200,
      opacity: 0,
    },
    visible: {
      x: 0,
      opacity: 1,
      transition: { duration: 1.3 },
    },
  };
  const [showYouTube, setShowYouTube] = useState(false);

  useEffect(() => {
    const handleLoad = () => {
      setShowYouTube(true);
    };

    window.addEventListener('load', handleLoad);

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);
  return (
    <section className={Style.about_us} id={"about_us"}>
      <Logo text={"About"} />
      <h1 className={Style.logo_title}>
        First residence experience in METAVerse{" "}
      </h1>
      <div className={Style.about_container_wrapper}>
        <div className={Style.about_container}>
          <div className={Style.item}>
            <h3 className={Style.about_title}>
              Apartments & Spaces in Decentraland
            </h3>
            <img src={cardLeft} className={Style.cardLeft} alt="card" />
            <img src={lineLeft} className={Style.lineLeft} alt="line" />
            <img src={lineLeftBig} className={Style.lineLeftbig} alt="line" />
            <h4 className={Style.about_subtitle}>
              {/*Profile data saved Two-line message Profile data saved Two-line*/}
              {/*messageProfile data saved*/}
            </h4>
          </div>

          <div className={Style.item}>
            <h3 className={Style.about_title}>
              Dozens of extra Smart Items and Upgrades
            </h3>
            <img src={cardRight} className={Style.cardRight} alt="card" />
            <img src={lineRight} className={Style.lineRight} alt="line" />
            <img src={lineRightBig} className={Style.lineRightbig} alt="line" />
            <h4 className={Style.about_subtitle}>
              {/*Profile data saved Two-line message Profile data saved Two-line*/}
              {/*messageProfile data saved*/}
            </h4>
          </div>

          <div className={Style.item}>
            <h3 className={Style.about_title}>
              From Cosy Studio, to Luxury Penthouse & Open Spaces
            </h3>
            <img src={cardLeft} className={Style.cardLeft} alt="card" />
            <img src={lineLeft} className={Style.lineLeft} alt="line" />
            <img src={lineLeftBig} className={Style.lineLeftbig} alt="line" />
            <h4 className={Style.about_subtitle}>
              {/*Profile data saved Two-line message Profile data saved Two-line*/}
              {/*messageProfile data saved*/}
            </h4>
          </div>

          <div className={Style.item}>
            <h3 className={Style.about_title}>
              Several Access Features (Public, Pin-Code, NFT, Wearables)
            </h3>
            <img src={cardRight} className={Style.cardRight} alt="card" />
            <img src={lineRight} className={Style.lineRight} alt="line" />
            <img src={lineRightBig} className={Style.lineRightbig} alt="line" />
            <h4 className={Style.about_subtitle}>
              {/*Profile data saved Two-line message Profile data saved Two-line*/}
              {/*messageProfile data saved*/}
            </h4>
          </div>

          <div className={Style.item}>
            <h3 className={Style.about_title}>
              Customise space with advanced builder with 700+ free units
            </h3>
            <img src={cardLeft} className={Style.cardLeft} alt="card" />
            <img src={lineLeft} className={Style.lineLeft} alt="line" />
            <img src={lineLeftBig} className={Style.lineLeftbig} alt="line" />
            <h4 className={Style.about_subtitle}>
              {/*Profile data saved Two-line message Profile data saved Two-line*/}
              {/*messageProfile data saved*/}
            </h4>
          </div>

          <div className={Style.item}>
            <h3 className={Style.about_title}>
            No-code solution for any Event (Party, Exhibitions)
            </h3>
            <img src={cardRight} className={Style.cardRight} alt="card" />
            <img src={lineRight} className={Style.lineRight} alt="line" />
            <img src={lineRightBig} className={Style.lineRightbig} alt="line" />
            <h4 className={Style.about_subtitle}>
              {/*Profile data saved Two-line message Profile data saved Two-line*/}
              {/*messageProfile data saved*/}
            </h4>
          </div>

          <div className={Style.item}>
            <h3 className={Style.about_title}>
            Make your own home, business, place  in Decentraland
            </h3>
            <img src={cardRight} className={Style.cardLeft} alt="card" />
            <img src={lineLeft} className={Style.lineRight} alt="line" />
            <h4 className={Style.about_subtitle}>
              {/*Profile data saved Two-line message Profile data saved Two-line*/}
              {/*messageProfile data saved*/}
            </h4>
          </div>
        </div>
      </div>
        <div className={Style.second_div}>
        <img src={cardRight} className={Style.bottomCard} alt="card" />
        <h3 className={Style.about_title}>Make your own home, business, place  in Decentraland</h3>
        <div className={Style.divider} />
        <h4 className={Style.about_subtitle}>
              {/*Profile data saved Two-line message Profile data saved Two-line*/}
              {/*messageProfile data saved*/}
        </h4>
        </div>
      <div className={Style.container}>

        {showYouTube && <YouTubeVideo />}
        <motion.div
          initial={"hidden"}
          animate={"visible"}
          viewport={{ once: true }}
          variants={Animation}
          className={Style.text__box}
        >
          <p className={Style.text}>
            <span>Meta Residence Tower</span> - The first in the Metaverse
            skyscraper with 10000 NFT apartments.
          </p>
          <p className={Style.text}>
            We offer 10,000 NFT apartments in Metaverse within a virtual
            skyscraper, with access granted through unique NFT keys. Our project
            empowers users to design their space choosing from four layout types
            and over 700 interior design items, along with interactive Smart
            Items and Upgrades for enhanced user interaction.
          </p>
          <p className={Style.text}>
            We offer four layout options for apartments—cozy studio, one or
            two-bedroom suits, and obviously spacious penthouse.
          </p>
          <p className={Style.text}>
            An interior editor is more than five hundred units based on
            surfaces, furniture, and decorative stuff and still adding... Choose
            any, then move over to your flat to find the best place and
            position. Save your layouts and change your space according to your
            mood.
          </p>
          <p className={Style.text}>
            An interior editor is more than five hundred units based on
            surfaces, furniture, and decorative stuff and still adding... Choose
            any, then move over to your flat to find the best place and
            position. Save your layouts and change your space according to your
            mood. Access to your apartments is always under control. There are
            three options - personal access for NFT key owner, public open, and
            pin-code access. Just open the access tab and set your pin-code,
            then give it to your friends or community.
          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default AboutUs;
