import React, {useEffect, useState} from "react";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Style from './App.module.css';
import Header from "../Header/Header";
import Banner from "../Banner/Banner";
import AboutUs from "../AboutUs/AboutUs";
import FAQ from "../FAQ/FAQ";
import Footer from "../Footer/Footer";
import Shop from "../Shop/Shop";
import Features from "../Features/Features";
import Up from "../Up/Up";
import TypesOfFlats from "../TypesOfFlats/TypesOfFlats";
import {useEthers} from "@usedapp/core";
import * as dclTx from 'decentraland-transactions'
import * as eth from 'eth-connect'
import {
    ARB_ABI,
    FLAT_ABI2,
    KEY_CONTRACT,
    MANA_ABI,
    MANA_CONTRACT,
    metaTransactionServerAddress,
    NODE_URL,
    oneBHKLayoutTokenIds,
    RATE_ABI,
    RATE_CONTRACT,
    studioLayoutTokenIds,
    twoBHKLayoutTokenIds,
    USDT_CONTRACT
} from "../../env";
import {ethers} from "ethers";
import {Multicall} from "ethereum-multicall";
import Partners from "../Partners/Partners";
import OurSocial from "../OurSocial/OurSocial";
import AccessFeatures from "../AccessFeatures/AccessFeatures";
import ResidentsGallery from "../ResidentsGallery/ResidentsGallery";
import Roadmap from "../Roadmap/Roadmap";

function App() {
    const {activateBrowserWallet, account, chainId, library} = useEthers();
    const [provider, setProvider] = useState();
    const [signer, setSigner] = useState();
    const [manaPrice, setManaPrice] = useState();
    const [alowanceMANA, setAlowanceMANA] = useState();
    const [alowanceUSDT, setAlowanceUSDT] = useState();
    const [ownerLists, setOwnerLists] = useState([])
    const [floorDensity, setFloorDensity] = useState();
    const [onSwitch, setOnSwitch] = useState(false);
    const [balancesMANA, setBalancesMANA] = useState(0);
    const [balancesUSDT, setBalancesUSDT] = useState(0);
    const [pricesInUSDT, setPricesInUSDT] = useState(0);
    const [pricesInMANA, setPricesInMANA] = useState(0);
    const [allowanceUSDT, setAllowanceUSDT] = useState(false);
    const [allowanceMana, setAllowanceMana] = useState(false);
    const [flat, setFlat] = useState(0);

    const handleToggle = () => {
        setOnSwitch(!onSwitch);
    };
    const providerInfura = new ethers.providers.JsonRpcProvider(NODE_URL); // replace with your Ethereum node URL
    // const providerInfura = new ethers.providers.JsonRpcProvider(NODE_URL_ARB); // replace with your Ethereum node URL
    useEffect(() => {
        fetchData()
        .then((array) => {
          setFloorDensity(array);
        })
    }, [])
    async function fetchData() {
        const response = await fetch("https://mrt.games/sitebackend");
        console.log("RESPONSE!!!", response)
        return await response.json();
    }

    useEffect(() => {
        const setProviderToState = async function () {
            if (account) {
                setProvider(library);
                setSigner(library.getSigner());
                // claim()

                getChainInfo()
            }
        };
        setProviderToState();


    }, [account, chainId]);


    useEffect(() => {
        console.log("manaPriceBefore", manaPrice)
        if (manaPrice) {
            console.log("manaPriceAfter", manaPrice)
            getFlatPrice(flat)
            checkAllowanceMana(flat)
            checkAllowanceUSDT(flat)
        }
    }, [manaPrice, flat]);


    async function checkAvailableFloorKeys(floor, sector) {
        // let ownerList: {flat: number, owner: string, public: boolean, ownerName: string}[] = [];
        let ownerList = [];
        const multicall = new Multicall({ethersProvider: providerInfura, tryAggregate: true});
        const nftContract = new ethers.Contract(
            KEY_CONTRACT,
            FLAT_ABI2,
            providerInfura
        );

        const contractCallContext = [
            {
                reference: 'Flat',
                contractAddress: KEY_CONTRACT,
                abi: FLAT_ABI2,
                calls: []
            }
        ]

        for (let i = 0; i < 50; i++) {
            if (i == 0 && floor == 0) //Floor 0 has no apartment 0
            {
                continue;
            }
            else {
                contractCallContext[0].calls.push({
                    reference: 'Flat',
                    methodName: 'ownerOf',
                    methodParameters: [floor * 100 + sector * 50 + i]

                })

            }
        }

        const resultsOfMulticall = await multicall.call(contractCallContext);
        // console.log("resultsOfMulticall", resultsOfMulticall)


        let counter = -1;
        for (const res of resultsOfMulticall.results.Flat.callsReturnContext) {

            counter++
            const flatOwner = res.returnValues[0];
            if (flatOwner != undefined) {
                ownerList.push({
                    flat: floor * 100 + sector * 50 + counter,
                    owner: flatOwner,
                    public: false,
                    ownerName: ""
                })
            } else {
                ownerList.push({flat: floor * 100 + sector * 50 + counter, owner: "", public: false, ownerName: ""})

            }
        }

        // console.log("ownerList", ownerList)

        return setOwnerLists(ownerList)
    }

    async function getChainInfo() {
        const manaContract = new ethers.Contract(
            MANA_CONTRACT,
            MANA_ABI,
            providerInfura
        )

        const usdtContract = new ethers.Contract(
            USDT_CONTRACT,
            MANA_ABI,
            providerInfura
        )

        const rateContract = new ethers.Contract(
            RATE_CONTRACT,
            RATE_ABI,
            providerInfura
        );

        const rate = await rateContract.getRate()
        const rateNumber = Number(rate.toString()) / Math.pow(10, 18)
        console.log("Rate of MANA: ", rateNumber)
        setManaPrice(rateNumber)

        const balanceMANA = await manaContract.balanceOf(account) / 10 ** 18
        console.log(`Balance of MANA: ${balanceMANA.toString()}`);

        const allowanceMANA = await manaContract.allowance(account, KEY_CONTRACT)
        console.log(`Allowance of MANA: ${allowanceMANA.toString()}`);
        setAlowanceMANA(allowanceMANA)

        const balanceUSDT = await usdtContract.balanceOf(account) / 10 ** 6
        console.log(`Balance of USDT: ${balanceUSDT.toString()}`);

        const allowanceUSDT = await manaContract.allowance(account, KEY_CONTRACT)
        console.log(`Allowance of USDT: ${allowanceUSDT.toString()}`);
        setAlowanceUSDT(allowanceUSDT)

        setBalancesMANA(balanceMANA)
        setBalancesUSDT(balanceUSDT)

        return {balanceMANA, balanceUSDT, allowanceMANA, allowanceUSDT}
    }

    function checkAllowanceMana(tokenId) {
        let {priceInUSDT, priceInMANA} = getFlatPrice(tokenId)

        console.log("checkAllowance priceInMANA", priceInMANA)

        priceInMANA = Number(priceInMANA) * 10 ** 18

        console.log("checkAllowance alowanceMANA", Number(alowanceMANA))
        console.log("checkAllowance priceInMANA2", priceInMANA)

        if (Number(alowanceMANA) > priceInMANA) {
            return setAllowanceMana(true)
        } else {
            return setAllowanceMana(false)
        }

    }

    function checkAllowanceUSDT(tokenId) {
        let {priceInUSDT, priceInMANA} = getFlatPrice(tokenId)

        console.log("checkAllowance priceInUSDT", priceInUSDT)

        priceInUSDT = Number(priceInUSDT) * 10 ** 6

        console.log("checkAllowance alowanceUSDT", Number(alowanceUSDT))
        console.log("checkAllowance priceInUSDT2", priceInUSDT)
        console.log("alowanceUSDT > priceInUSDT", Number(alowanceUSDT) > priceInUSDT)

        if (Number(alowanceUSDT) > priceInUSDT) {
            return setAllowanceUSDT(true)
        } else {
            return setAllowanceUSDT(false)
        }

    }

    async function approveMANA() {
        try {
            console.log("START APPROVE MANA1")
            const requestManager = new ethers.providers.Web3Provider(window.ethereum)
            const metaProvider = new eth.HTTPProvider(NODE_URL)
            const metaRequestManager = new eth.RequestManager(metaProvider)
            const manaConfig = dclTx.getContract(dclTx.ContractName.MANAToken, 137)
              let contract = await new eth.ContractFactory(metaRequestManager, manaConfig.abi).at(
                manaConfig.address
              )



              const functionHex = contract.approve.toPayload(
                KEY_CONTRACT,
                '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
              )


              const txHash = dclTx.sendMetaTransaction(
                requestManager,
                metaRequestManager,
                functionHex.data,
                manaConfig,
                { serverURL: metaTransactionServerAddress }
              )


            notify(txHash, await txHash);

            //notify(txHash, txHash)

            setAllowanceMana(true)

        } catch (e) {
            console.log("Mint Error", e)
        }

    }

    async function approveUSDT() {

        try {

            const requestManager = new ethers.providers.Web3Provider(window.ethereum)
            const metaProvider = new eth.HTTPProvider(NODE_URL)
            const metaRequestManager = new eth.RequestManager(metaProvider)
            const manaConfig = dclTx.getContract(dclTx.ContractName.MANAToken, 137)
            const usdtconf = {
                version: manaConfig.version,
                abi: manaConfig.abi,
                address: USDT_CONTRACT,
                name: "(PoS) Tether USD",
                chainId: 137
              }
              let contract = await new eth.ContractFactory(metaRequestManager, usdtconf.abi).at(
                usdtconf.address
              )



              const functionHex = contract.approve.toPayload(
                KEY_CONTRACT,
                '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
              )
              //console.log("ДАТА", functionHex)


              const txHash = dclTx.sendMetaTransaction(
                requestManager,
                metaRequestManager,
                functionHex.data,
                usdtconf,
                { serverURL: metaTransactionServerAddress }
              )

            notify(txHash, await txHash)

            //notify(txHash, txHash)

            setAllowanceUSDT(true)
        } catch (e) {
            console.log("Mint Error", e)

        }

    }

    async function mintMANA(tokenId) {

        try {
            console.log("START MINT MANA", tokenId)

            const requestManager = new ethers.providers.Web3Provider(window.ethereum)
            const metaProvider = new eth.HTTPProvider(NODE_URL)
            const metaRequestManager = new eth.RequestManager(metaProvider)

            const mrtConfig = {
                version: '1',
                abi: FLAT_ABI2,
                address: KEY_CONTRACT,
                name: 'Meta Residence Tower',
                chainId: 137
              }

              let contract = await new eth.ContractFactory(metaRequestManager, mrtConfig.abi).at(
                mrtConfig.address
              )



              const functionHex = contract.mintMANA.toPayload(tokenId)

              const txHash = dclTx.sendMetaTransaction(
                requestManager,
                metaRequestManager,
                functionHex.data,
                mrtConfig,
                { serverURL: metaTransactionServerAddress }
              )

            notify(txHash, await txHash)

        } catch (e) {
            console.log("Mint Error", e)
            // setTxWaiting(false)

        }

    }

    async function mintUSDT(tokenId) {

        try {
            const requestManager = new ethers.providers.Web3Provider(window.ethereum)
            const metaProvider = new eth.HTTPProvider(NODE_URL)
            const metaRequestManager = new eth.RequestManager(metaProvider)

            const mrtConfig = {
                version: '1',
                abi: FLAT_ABI2,
                address: KEY_CONTRACT,
                name: 'Meta Residence Tower',
                chainId: 137
              }

              let contract = await new eth.ContractFactory(metaRequestManager, mrtConfig.abi).at(
                mrtConfig.address
              )



              const functionHex = contract.mintUSDT.toPayload(tokenId)

              const txHash = dclTx.sendMetaTransaction(
                requestManager,
                metaRequestManager,
                functionHex.data,
                mrtConfig,
                { serverURL: metaTransactionServerAddress }
              )

            notify(txHash, await txHash)

        } catch (e) {
            console.log("Mint Error", e)
            // setTxWaiting(false)

        }

    }

    async function claim() {

        try {
            console.log("START CLAIM")
            // setTxWaiting(true)
            const towerNftContract = new ethers.Contract(
                "0x67a24CE4321aB3aF51c2D0a4801c3E111D88C9d9",
                ARB_ABI,
                signer
            ).populateTransaction

            // const gasPrice = await signer.getGasPrice();
            // const value = (amount * PRICE_WEI).toString()
            // const estimateTransaction = await jaguarsNftContract.mint(value)
            // const estimateGasLimit = props.provider.estimateGas(estimateTransaction).then(res => console.log(res))
            const mintTx = await towerNftContract.claim({
                // gasPrice: gasPrice.add(10000000000),
                // gasLimit: gasLimit,
                // value: value
            })

            console.log("mintTx", mintTx)
            const txSent = await library.getSigner().sendTransaction(mintTx)
            // const txLink = getExplorerTransactionLink(txSent.hash, 80001)
            console.log("txSent", txSent)

            const wait = txSent.wait()
            notify(wait, txSent.hash)

            console.log("wait", wait)


        } catch (e) {
            console.log("Mint Error", e)
            // setTxWaiting(false)

        }

    }

    function getFloor(tokenId) {
        let floor
        if (tokenId.toString().length == 4){
            floor = Number(tokenId.toString().slice(0, 2));
        } else if (tokenId.toString().length == 3) {
            floor = Number(tokenId.toString().slice(0, 1));
        } else {
            floor = 0
        }
        console.log("floor", floor)
        return floor;
    }

    function getFlat(tokenId) {
        const flat = (tokenId % 100);
        console.log("flat", flat)
        return flat;

    }

    function getFlatLayout(tokenId) {
        const floor = getFloor(tokenId);
        const flat = getFlat(tokenId);
        let layout

        if (floor < 90) {
            for (let i = 0; i < studioLayoutTokenIds.length; i++) {
                if (flat == studioLayoutTokenIds[i]) {
                    return "Studio";

                }
            }
            for (let i = 0; i < oneBHKLayoutTokenIds.length; i++) {
                if (flat == oneBHKLayoutTokenIds[i]) {
                    return "1BHK";

                }
            }

            for (let i = 0; i < twoBHKLayoutTokenIds.length; i++) {
                if (flat == twoBHKLayoutTokenIds[i]) {
                    return "2BHK";

                }
            }

        } else if (floor > 89 && floor < 100) {

            for (let i = 0; i < studioLayoutTokenIds.length; i++) {
                if (flat == studioLayoutTokenIds[i]) {
                    return "Studio";

                }
            }

            for (let i = 0; i < oneBHKLayoutTokenIds.length; i++) {
                if (flat == oneBHKLayoutTokenIds[i]) {
                    return "Penthouse";

                }
            }

            for (let i = 0; i < twoBHKLayoutTokenIds.length; i++) {
                if (flat == twoBHKLayoutTokenIds[i]) {
                    return "Penthouse";


                }
            }

        }

    }

    function getFlatPrice(tokenId) {
        const layout = getFlatLayout(tokenId)
        const floor = getFloor(tokenId)
        let priceInUSDT
        let priceInMANA
        const multiplayer = 0.5
        const addition = floor * multiplayer

        if (layout == "Studio") {
            priceInUSDT = 69
        } else if (layout == "1BHK") {
            priceInUSDT = 119
        } else if (layout == "2BHK") {
            priceInUSDT = 169
        } else if (layout == "Penthouse") {
            priceInUSDT = 350

        }

        priceInMANA = (priceInUSDT + addition) / manaPrice
        priceInUSDT = (priceInUSDT + addition)

        console.log("Layout", layout)
        console.log("manaPrice", manaPrice)
        console.log("priceInUSDT", priceInUSDT)
        console.log("addition", addition)
        console.log("priceInMANA", priceInMANA)

        setPricesInUSDT(priceInUSDT)
        setPricesInMANA(priceInMANA)

        return {priceInUSDT, priceInMANA}

    }

    return (
        <section className={Style.app}>
            <Header activateBrowserWallet={activateBrowserWallet} account={account} handleToggle={handleToggle}
                    onSwitch={onSwitch} balanceUSDT={balancesUSDT} balanceMANA={balancesMANA}/>
            <main className={Style.main}>
                <ToastContainer position={'top-right'} autoClose={false} closeOnClick={false}/>
                <Banner/>
                <AboutUs/>
                <Features/>
                {/*<Roadmap/>*/}
                <TypesOfFlats priceInMANA={pricesInMANA}/>
                <Shop floorDensity={floorDensity} checkAvailableFloorKeys={checkAvailableFloorKeys} account={account} ownerLists={ownerLists}
                      handleToggle={handleToggle} activateBrowserWallet={activateBrowserWallet}
                      onSwitch={onSwitch} setFlat={setFlat} priceInUSDT={pricesInUSDT} priceInMANA={pricesInMANA}
                      allowanceMana={allowanceMana} allowanceUSDT={allowanceUSDT} approveMANA={approveMANA}
                      approveUSDT={approveUSDT} mintMANA={mintMANA} mintUSDT={mintUSDT}/>
                <ResidentsGallery/>
                {/*<Partners/>*/}
                <AccessFeatures/>
                <FAQ/>
                {/*<OurSocial/>*/}
                <Up/>
                <Footer/>
            </main>
        </section>
    );
}

export default App;

function notify(txPromise, txHash) {
    toast.promise(
        txPromise,
        {
            // pending: 'Waiting for Confirmation',
            pending: {
                render() {
                    return <a href={`https://polygonscan.com/tx/${txHash}`} target={"_blank"} rel="noreferrer">Waiting for Confirmation 🔗</a>
                }
            },
            // success: `Transaction Success ${link}👌 `,
            success: {
                render() {
                    return <a href={`https://polygonscan.com/tx/${txHash}`} target={"_blank"} rel="noreferrer">Transaction Success 🔗</a>
                },
                icon: "🟢",
            },
            error: 'Transaction rejected 🤯'
        }
    )
}
