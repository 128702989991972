import React from "react";
import Style from './SwitchWallet.module.css'
import icon from '../../images/header/header__button.webp'
import iconT from '../../images/header/button__header_T.webp'

const SwitchWallet = ({onSwitch, handleToggle}) => {

    return (
        <div className={Style.container}>
            <div className={`${Style.left__box} ${onSwitch ? Style.opacity  : ''}`}>
                <img className={Style.img} loading="lazy" src={icon} alt={'img'}/>
                <span className={Style.text}>MANA</span>
            </div>
            <label>
                <input type='checkbox' checked={onSwitch} onChange={handleToggle} />
                <span className={Style.switch_slider}></span>
            </label>
            <div className={`${Style.right__box} ${onSwitch ? '' : Style.opacity}`}>
                <img className={Style.img} loading="lazy" src={iconT} alt={'img'}/>
                <span className={Style.text}>USDT</span>
            </div>
        </div>
    )
}

export default SwitchWallet
