import React from "react";
import Style from "./Nav.module.css";

const Nav = ({link, text , isOpen, toggleMenu , style}) => {
    return (
        <a href={`#${link}`} className={`${Style.nav} ${Style[`nav__${style}`]}`} onClick={isOpen? toggleMenu : null}>{text}</a>
    )
}

export default Nav
