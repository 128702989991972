import React from "react";
import Style from './AccessFeaturesCards.module.css'
const AccessFeaturesCards = ({title, text, img, style}) => {
    return (
        <div className={`${Style.card} ${Style[`card_${style}`]}`}>
            <div className={Style.text_box}>
                <p className={Style.title}>{title}</p>
                <p className={Style.text}>{text}</p>
            </div>
            <img className={Style.img} loading="lazy" src={img} alt={title}/>
        </div>
    )
}

export default AccessFeaturesCards
