import React from "react";
import Style from './FeaturesAccordion.module.css';

const FeaturesAccordion = ({item, isOpen}) => {
    return (
        <div className={`${Style.accordion} ${isOpen === item.id && Style.accordionActive}`}>
            <div className={Style.accordionHeader}>
                <h3 className={`${Style.title} ${isOpen === item.id && Style.titleActive}`}>{item.title}</h3>
                <img className={Style.accordionImg} loading="lazy" src={item.icon} alt={'img'}/>
            </div>
            {isOpen === item.id && <div className={Style.accordionContent}>{item.subtitle}</div>}
        </div>
    )
}

export default FeaturesAccordion
