import React, {useRef} from "react";
import YouTube from "react-youtube";
import Style from './YouTube.module.css'

const YouTubeVideo = () => {
    const videoId = 'U8IMsaJTt-A';
    const playerRef = useRef(null);

    const opts = {
        playerVars: {
            autoplay: 0,
            controls: 1,
            disablekb: 0,
            modestbranding: 0,
            rel: 0,
            showinfo: 0,
            fs: 0,
        },
    };

    return (
        <div className={Style.box}>
            <YouTube className={Style.youtube} videoId={videoId} opts={opts} ref={playerRef} />
        </div>
    )
}

export default YouTubeVideo

