import image1 from '../../images/ResidentsGallery/01m_00000.webp'
import image2 from '../../images/ResidentsGallery/02m_00000.webp'
import image3 from '../../images/ResidentsGallery/03m_00000.webp'
import image4 from '../../images/ResidentsGallery/04m_00000.webp'
import image5 from '../../images/ResidentsGallery/05m_00000.webp'
import image6 from '../../images/ResidentsGallery/06m_00000.webp'
import image7 from '../../images/ResidentsGallery/07m_00000.webp'
import image8 from '../../images/ResidentsGallery/08m_00000.webp'
import image9 from '../../images/ResidentsGallery/09m_00000.webp'
import image10 from '../../images/ResidentsGallery/10m_00000.webp'
import image11 from '../../images/ResidentsGallery/11m_00000.webp'
import image12 from '../../images/ResidentsGallery/12m_00000.webp'
import image13 from '../../images/ResidentsGallery/13m_00000.webp'
import image14 from '../../images/ResidentsGallery/14m_00000.webp'
import image15 from '../../images/ResidentsGallery/15m_00000.webp'
import image16 from '../../images/ResidentsGallery/16m_00000.webp'
import image17 from '../../images/ResidentsGallery/17m_00000.webp'
import image18 from '../../images/ResidentsGallery/18m_00000.webp'
import image19 from '../../images/ResidentsGallery/19m_00000.webp'
import image20 from '../../images/ResidentsGallery/20m_00000.webp'
import image21 from '../../images/ResidentsGallery/21m_00000.webp'
import image22 from '../../images/ResidentsGallery/22m_00000.webp'
import image23 from '../../images/ResidentsGallery/23m_00000.webp'
import image24 from '../../images/ResidentsGallery/24m_00000.webp'
import image25 from '../../images/ResidentsGallery/25m_00000.webp'
import image26 from '../../images/ResidentsGallery/26m_00000.webp'
import image27 from '../../images/ResidentsGallery/27m_00000.webp'
import image28 from '../../images/ResidentsGallery/28m_00000.webp'
import image29 from '../../images/ResidentsGallery/29m_00000.webp'
import image30 from '../../images/ResidentsGallery/30m_00000.webp'
import image31 from '../../images/ResidentsGallery/31m_00000.webp'
import image32 from '../../images/ResidentsGallery/32m_00000.webp'
import image33 from '../../images/ResidentsGallery/33m_00000.webp'
import image34 from '../../images/ResidentsGallery/34m_00000.webp'
import image35 from '../../images/ResidentsGallery/35m_00000.webp'
import image36 from '../../images/ResidentsGallery/36m_00000.webp'
import image37 from '../../images/ResidentsGallery/37m_00000.webp'
import image38 from '../../images/ResidentsGallery/38m_00000.webp'
import image39 from '../../images/ResidentsGallery/39m_00000.webp'
import image40 from '../../images/ResidentsGallery/40m_00000.webp'
import image41 from '../../images/ResidentsGallery/41m_00000.webp'
import image42 from '../../images/ResidentsGallery/42m_00000.webp'
import image43 from '../../images/ResidentsGallery/43m_00000.webp'
import image44 from '../../images/ResidentsGallery/44m_00000.webp'
import image45 from '../../images/ResidentsGallery/45m_00000.webp'
import image46 from '../../images/ResidentsGallery/46m_00000.webp'
import image47 from '../../images/ResidentsGallery/47m_00000.webp'
import image48 from '../../images/ResidentsGallery/48m_00000.webp'
import image49 from '../../images/ResidentsGallery/49m_00000.webp'
import image50 from '../../images/ResidentsGallery/50m_00000.webp'
import image51 from '../../images/ResidentsGallery/51m_00000.webp'
import image52 from '../../images/ResidentsGallery/52m_00000.webp'
import image53 from '../../images/ResidentsGallery/53m_00000.webp'
import image54 from '../../images/ResidentsGallery/54m_00000.webp'
import image55 from '../../images/ResidentsGallery/55m_00000.webp'
import image56 from '../../images/ResidentsGallery/56m_00000.webp'
import image57 from '../../images/ResidentsGallery/57m_00000.webp'

export const images = [
    {
        id: 1,
        url: image1
    },
    {
        id: 2,
        url: image2
    },
    {
        id: 3,
        url: image3
    },
    {
        id: 4,
        url: image4
    },
    {
        id: 5,
        url: image5
    },
    {
        id: 6,
        url: image6
    },
    {
        id: 7,
        url: image7
    },
    {
        id: 8,
        url: image8
    },
    {
        id: 9,
        url: image9
    },
    {
        id: 10,
        url: image10
    },
    {
        id: 11,
        url: image11
    },
    {
        id: 12,
        url: image12
    },
    {
        id: 13,
        url: image13
    },
    {
        id: 14,
        url: image14
    },
    {
        id: 15,
        url: image15
    },
    {
        id: 16,
        url: image16
    },
    {
        id: 17,
        url: image17
    },
    {
        id: 18,
        url: image18
    },
    {
        id: 19,
        url: image19
    },
    {
        id: 20,
        url: image20
    },
    {
        id: 21,
        url: image21
    },
    {
        id: 22,
        url: image22
    },
    {
        id: 23,
        url: image23
    },
    {
        id: 24,
        url: image24
    },
    {
        id: 25,
        url: image25
    },
    {
        id: 26,
        url: image26
    },
    {
        id: 27,
        url: image27
    },
    {
        id: 28,
        url: image28
    },
    {
        id: 29,
        url: image29
    },
    {
        id: 30,
        url: image30
    },
    {
        id: 31,
        url: image31
    },
    {
        id: 32,
        url: image32
    },
    {
        id: 33,
        url: image33
    },
    {
        id: 34,
        url: image34
    },
    {
        id: 35,
        url: image35
    },
    {
        id: 36,
        url: image36
    },
    {
        id: 37,
        url: image37
    },
    {
        id: 38,
        url: image38
    },
    {
        id: 39,
        url: image39
    },
    {
        id: 40,
        url: image40
    },
    {
        id: 41,
        url: image41
    },
    {
        id: 42,
        url: image42
    },
    {
        id: 43,
        url: image43
    },
    {
        id: 44,
        url: image44
    },
    {
        id: 45,
        url: image45
    },
    {
        id: 46,
        url: image46
    },
    {
        id: 47,
        url: image47
    },
    {
        id: 48,
        url: image48
    },
    {
        id: 49,
        url: image49
    },
    {
        id: 50,
        url: image50
    },
    {
        id: 51,
        url: image51
    },
    {
        id: 52,
        url: image52
    },
    {
        id: 53,
        url: image53
    },
    {
        id: 54,
        url: image54
    },
    {
        id: 55,
        url: image55
    },
    {
        id: 56,
        url: image56
    },
    {
        id: 57,
        url: image57
    },
]
