import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Style from "./Shop.module.css";
import Logo from "../Logo/Logo";
import arrow from "../../images/Shop/arrow.svg";
import Floor from "./Floor/Floor";
import Apartments from "./Apartments/Apartments";
import ChoiceApartment from "./ChoiceApartment/ChoiceApartment";
import Switch from "./Switch/Switch";

const Shop = ({
  floorDensity,
  account,
  checkAvailableFloorKeys,
  ownerLists,
  handleToggle,
  onSwitch,
  setFlat,
  priceInMANA,
  priceInUSDT,
  activateBrowserWallet,
  allowanceMana,
  allowanceUSDT,
  approveMANA,
  approveUSDT,
  mintMANA,
  mintUSDT,
}) => {
  const steps = ["1.Choose floor", "2.Choose flat", "3.Buy flat"];
  const [currentStep, updateCurrentStep] = useState(1);
  const [selectedSwitch, setSelectedSwitch] = useState(false);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [selectedApartment, setSelectedApartment] = useState(null);
  const [current, updateCurrent] = useState(0);
  const [apartment, setApartment] = useState([]);
  const [nameApartment, setNameApartment] = useState(null);

  function dataApartment(data, index, name) {
    setSelectedApartment(index);
    setApartment(data);
    setNameApartment(name);
    setFlat(index);
  }

  function updateStep(step) {
    updateCurrent(step);
    setSelectedFloor(step);
  }

  const toggleSwitch = () =>
    setSelectedSwitch((previousState) => !previousState);

  function name() {
    if (currentStep === 1) {
      return "1.Choose floor";
    }
    if (currentStep === 2) {
      return "2.Choose flat";
    }
    if (currentStep === 3) {
      return "3.Buy flat";
    }
    return name;
  }

  function array() {
    if (currentStep === 1) {
      return (
        <Floor
          floorDensity={floorDensity}
          updateStep={updateStep}
          current={current}
          isEnabled={selectedSwitch}
        />
      );
    }
    if (currentStep === 2) {
      return (
        <ChoiceApartment
          selectedCard={selectedFloor}
          isEnabled={selectedSwitch}
          selectedApartment={selectedApartment}
          ownerLists={ownerLists}
          dataApartment={dataApartment}
        />
      );
    }
    if (currentStep === 3) {
      return (
        <Apartments
          apartment={apartment}
          selectedSwitch={selectedSwitch}
          nameApartment={nameApartment}
          selectedFloor={selectedFloor}
          handleToggle={handleToggle}
          onSwitch={onSwitch}
          priceInMANA={priceInMANA}
          priceInUSDT={priceInUSDT}
          account={account}
          activateBrowserWallet={activateBrowserWallet}
          allowanceMana={allowanceMana}
          allowanceUSDT={allowanceUSDT}
          approveMANA={approveMANA}
          approveUSDT={approveUSDT}
          mintMANA={mintMANA}
          mintUSDT={mintUSDT}
        />
      );
    }
    return array;
  }

  useEffect(() => {
    if (selectedFloor) {
      checkAvailableFloorKeys(selectedFloor - 1, selectedSwitch ? 1 : 0); // sector 0=A, 1=B
    }
  }, [account, selectedFloor, selectedSwitch]);

  const Animation = {
    hidden: {
      y: -50,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 1 },
    },
  };

  const onClickNav = (item) => {
    currentStep > item && updateCurrentStep(item);
  };

  return (
    <section className={Style.shop} id={"mint"}>
      <Logo text={"choose apartment"} />
      <div className={Style.shop__container}>
        <motion.div
          className={Style.nav__container}
          initial={"hidden"}
          whileInView={"visible"}
          viewport={{ once: true }}
          variants={Animation}
        >
          {steps.map((item, index) => (
            <div
              key={index}
              onClick={() => onClickNav(index + 1)}
              className={`${Style.nav} 
                             ${currentStep > index + 1 && Style.cursor}
                             ${currentStep === index + 1 && Style.nav__active}
                                 ${
                                   index + 1 < currentStep && Style.nav__active
                                 }`}
            >
              {item}
            </div>
          ))}
          <img
            className={Style.button__arrow}
            loading="lazy"
            src={arrow}
            alt={"arrow"}
          />
          <img
            className={Style.button__arrow2}
            loading="lazy"
            src={arrow}
            alt={"arrow"}
          />
        </motion.div>
        <div className={`${Style.nav} ${Style.nav__768px}`}>{name()}</div>
        {currentStep <= 2 ? (
          <Switch toggleSwitch={toggleSwitch} isEnabled={selectedSwitch} />
        ) : null}
        <div className={Style.container}>{array()}</div>
        <div className={`${Style.button__container}`}>
          <button
            className={`${Style.button} ${Style.button__back} ${
              currentStep === 1 && Style.disabled
            }`}
            disabled={currentStep === 1}
            onClick={() => updateCurrentStep(currentStep - 1)}
          >
            <img
              className={`${Style.arrow} ${Style.arrow__back}`}
              loading="lazy"
              src={arrow}
              alt={"arrow"}
            />
            Back
          </button>
          <button
            className={`${Style.button} ${Style.button__next} ${
              currentStep === steps.length && Style.disabled
            }
                        ${
                          currentStep === 1
                            ? selectedFloor === null && Style.disabled
                            : ""
                        }
                        ${
                          currentStep === 2
                            ? selectedApartment === null && Style.disabled
                            : ""
                        }`}
            disabled={currentStep === steps.length}
            onClick={() => updateCurrentStep(currentStep + 1)}
          >
            Next
            <img
              className={Style.arrow}
              loading="lazy"
              src={arrow}
              alt={"arrow"}
            />
          </button>
        </div>
      </div>
    </section>
  );
};

export default Shop;
