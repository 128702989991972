import React from "react";
import {motion} from "framer-motion";
import Style from "../Banner.module.css";

const Social = ({flex}) => {

    const Animation2 = {
        hidden: {
            y: -50,
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1.5,
            transition: {duration: 1}
        }
    }

    const Animation = {
        hidden: {
            x: -200,
            opacity: 0,
        },
        visible: {
            x: 0,
            opacity: 1.5,
            transition: {duration: 1}
        }
    }

    return (
        <motion.div initial={'hidden'}
                    animate={'visible'}
                    className={`${Style.social__container} ${Style[`direction__${flex}`]}`}
                    variants={flex === 'footer' ? Animation2 : Animation}
        >
            {/*Twitter*/}
            <a href={'https://twitter.com/residence_meta'} className={`${Style.social} ${Style[`social__${flex}`]}`}>
                <svg className={`${Style.social__svg} ${Style[`svg__${flex}`]}`} width="23" height="18" viewBox="0 0 23 18" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M22.4134 3.44536C22.2834 3.25302 22.0455 3.144 21.7978 3.16353L20.7013 3.2504L21.7494 1.3288C21.8742 1.09993 21.8249 0.824187 21.6268 0.643054C21.4287 0.461921 21.1258 0.415749 20.8735 0.528093L18.0995 1.76368C16.3856 0.940789 14.2444 1.18505 12.7711 2.40571C11.5414 3.42447 10.895 4.99115 11.0156 6.5535C7.80164 6.26053 5.08116 4.58744 3.4725 1.88407C3.36577 1.70474 3.16566 1.58743 2.94202 1.57302C2.71795 1.55884 2.50208 1.64915 2.36868 1.8131C1.37094 3.03896 1.30093 4.71974 2.0831 6.10252C1.86749 6.05299 1.63805 5.98596 1.38361 5.90865C1.16564 5.84236 0.925333 5.88482 0.750397 6.02045C0.57546 6.15607 0.491363 6.3651 0.528773 6.57139C0.838273 8.27795 1.93248 9.58952 3.66097 10.3658C3.43591 10.4371 3.20625 10.495 2.97273 10.5393C2.74071 10.5833 2.55285 10.7376 2.48134 10.9432C2.40983 11.1486 2.46575 11.3732 2.62767 11.5306C3.87241 12.7407 5.67049 13.3363 6.94766 13.622C5.49551 14.6562 3.92795 14.8293 1.65012 14.7582C1.38645 14.7509 1.14352 14.886 1.03267 15.1034C0.921854 15.3208 0.964848 15.5775 1.14193 15.755C2.09148 16.7069 5.55095 17.7803 9.18764 17.8607C9.33148 17.8639 9.4782 17.8656 9.62801 17.8656C12.1815 17.8656 15.5561 17.3678 17.9784 15.1648C19.8118 13.4973 20.9586 11.5778 21.3867 9.45956C21.7326 7.74873 21.514 6.37873 21.409 5.72029C21.4013 5.67189 21.393 5.62053 21.3863 5.5756L22.412 4.07208C22.5428 3.88029 22.5434 3.63771 22.4134 3.44536Z"
                        fill="#D9D9D9"/>
                </svg>
            </a>
            {/*Discord*/}
            <a href={'https://discord.gg/fgwHmvpDwf'} className={`${Style.social} ${Style[`social__${flex}`]}`}>
                <svg className={`${Style.social__svg} ${Style[`svg__${flex}`]}`} width="26" height="16" viewBox="0 0 26 16" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.04456 12.7009C11.505 14.639 16.1518 15.0165 21.4176 11.9999C21.379 12.0525 20.3374 13.456 17.5982 14.1753C18.1769 14.8946 18.9678 15.7191 18.9678 15.7191C20.7232 15.7191 22.44 15.263 23.9253 14.3858C25.0827 13.684 25.7385 12.4735 25.6035 11.2104C25.372 9.05245 24.8126 6.94719 23.9446 4.92969C22.845 2.29813 20.2023 0.473548 17.1352 0.210371C16.8652 0.192819 16.6723 0.192819 16.5565 0.192819L16.2479 0.473496C19.778 1.38579 21.5334 2.80683 21.5719 2.85949C16.1514 0.368235 9.74721 0.333183 4.28812 2.75423C4.28812 2.75423 6.02423 1.19282 9.86289 0.368236L9.63142 0.157715C9.22631 0.157715 8.8405 0.192819 8.43545 0.245423C5.59982 0.684017 3.22719 2.45594 2.20481 4.89454C1.31745 6.9998 0.738772 9.21032 0.526598 11.4559C0.410861 12.6489 1.02814 13.8244 2.10837 14.5086C3.53581 15.3682 5.23331 15.8244 6.95012 15.8244C6.95012 15.8244 7.64454 14.9998 8.33902 14.263C5.73486 13.5612 4.67393 12.1577 4.65463 12.1051L5.14472 12.3327C5.4377 12.4688 5.73807 12.5921 6.04456 12.7009ZM9.07203 11.1753C7.81817 11.1402 6.83438 10.1753 6.87298 9.01735C6.91158 7.92964 7.87607 7.05245 9.07203 7.01735C10.3259 7.05245 11.3097 8.01735 11.2711 9.17527C11.2132 10.263 10.268 11.1402 9.07203 11.1753ZM16.9423 11.1753C15.6884 11.1402 14.7046 10.1753 14.7432 9.01735C14.7818 7.92964 15.7463 7.05245 16.9423 7.01735C18.1962 7.05245 19.1799 8.01735 19.1413 9.17527C19.1028 10.263 18.1383 11.1402 16.9423 11.1753Z"
                        fill="#D9D9D9"/>
                    <defs>
                        <clipPath id="clip0_9_7916">
                            <rect width="25.2891" height="16" fill="white" transform="translate(0.237244)"/>
                        </clipPath>
                    </defs>
                </svg>
            </a>


            {/*/!*Буква M*!/*/}

            {/*<a href={'https://LINK'} className={`${Style.social} ${Style[`social__${flex}`]}`}>*/}
            {/*    <svg className={`${Style.social__svg} ${Style[`svg__${flex}`]}`} width="26" height="16" viewBox="0 0 26 16" fill="none"*/}
            {/*         xmlns="http://www.w3.org/2000/svg">*/}
            {/*        <svg width="26" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
            {/*            <path d="M10.3626 10.0371L14.3157 0.433594H20.2454V0.831055H19.7727C19.343 0.831055 18.9993 0.90625 18.7415 1.05664C18.5624 1.1569 18.4192 1.3252 18.3118 1.56152C18.233 1.7334 18.1936 2.18099 18.1936 2.9043V12.5078C18.1936 13.2526 18.233 13.7181 18.3118 13.9043C18.3906 14.0905 18.5517 14.2552 18.7952 14.3984C19.0387 14.5345 19.3645 14.6025 19.7727 14.6025H20.2454V15H12.6399V14.6025H13.1126C13.5423 14.6025 13.886 14.5273 14.1438 14.377C14.3229 14.2767 14.4661 14.1048 14.5735 13.8613C14.6523 13.6895 14.6917 13.2383 14.6917 12.5078V1.71191L9.10574 15H8.84793L3.17605 1.80859V12.0674C3.17605 12.7835 3.19396 13.224 3.22976 13.3887C3.32286 13.7467 3.52338 14.0404 3.83133 14.2695C4.14643 14.4915 4.63699 14.6025 5.30301 14.6025V15H0.31863V14.6025H0.469021C0.791286 14.6097 1.09207 14.5596 1.37136 14.4521C1.65066 14.3376 1.86192 14.1872 2.00515 14.001C2.14838 13.8148 2.25939 13.5534 2.33816 13.2168C2.35248 13.138 2.35965 12.7728 2.35965 12.1211V2.9043C2.35965 2.16667 2.32026 1.70833 2.24148 1.5293C2.16271 1.3431 2.00157 1.18197 1.75808 1.0459C1.51459 0.902669 1.18875 0.831055 0.780544 0.831055H0.31863V0.433594H6.2698L10.3626 10.0371Z" fill="#D9D9D9"/>*/}
            {/*        </svg>*/}

            {/*        <defs>*/}
            {/*            <clipPath id="clip0_9_7916">*/}
            {/*                <rect width="25.2891" height="16" fill="white" transform="translate(0.237244)"/>*/}
            {/*            </clipPath>*/}
            {/*        </defs>*/}
            {/*    </svg>*/}
            {/*</a>*/}

            {/*/!*ГИТХАБ*!/*/}

            {/*<a href={'https://LINK'} className={`${Style.social} ${Style[`social__${flex}`]}`}>*/}
            {/*    <svg className={`${Style.social__svg} ${Style[`svg__${flex}`]}`} width="26" height="25" viewBox="0 0 26 16" fill="none"*/}
            {/*         xmlns="http://www.w3.org/2000/svg">*/}
            {/*        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 3 19 19" fill="none">*/}
            {/*            <path d="M6.36158 17.8198C6.91546 17.8198 7.07199 17.6031 7.07199 17.3261C7.07199 17.0492 7.07199 16.4471 7.05995 15.5922C4.18218 16.2063 3.5681 14.2196 3.5681 14.2196C3.0985 13.0516 2.41217 12.7265 2.41217 12.7265C1.47298 12.1004 2.48442 12.1124 2.48442 12.1124C3.51993 12.1847 4.07381 13.16 4.07381 13.16C5.00096 14.7253 6.49403 14.2677 7.08403 14.0028C7.1322 13.4851 7.36097 12.9914 7.74628 12.6302C5.44647 12.3773 3.02626 11.4983 3.02626 7.59709C3.01422 6.58566 3.39952 5.59831 4.09789 4.86381C3.97749 4.61096 3.6283 3.57544 4.18218 2.16666C4.18218 2.16666 5.04912 1.88972 7.03587 3.21421C8.73363 2.75666 10.5157 2.75666 12.2134 3.21421C14.1761 1.90176 15.0551 2.16666 15.0551 2.16666C15.609 3.5634 15.2598 4.59892 15.1635 4.86381C15.8618 5.59831 16.2351 6.58566 16.2231 7.59709C16.2231 11.5104 13.8028 12.3653 11.491 12.6181C11.8522 12.9192 12.1894 13.5453 12.1894 14.4965C12.1894 15.8692 12.1773 16.9529 12.1773 17.278C12.1773 17.5429 12.2977 17.7596 12.8877 17.7596L6.36158 17.8198Z" fill="white"/>*/}
            {/*        </svg>*/}

            {/*        <defs>*/}
            {/*            <clipPath id="clip0_9_7916">*/}
            {/*                <rect width="25.2891" height="16" fill="white" transform="translate(0.237244)"/>*/}
            {/*            </clipPath>*/}
            {/*        </defs>*/}
            {/*    </svg>*/}
            {/*</a>*/}

            {/*OpenSea*/}

            <a href={'https://opensea.io/collection/meta-residence-tower'} className={`${Style.social} ${Style[`social__${flex}`]}`}>
                <svg className={`${Style.social__svg} ${Style[`svg__${flex}`]}`} width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M4.53645 15.6407H10.2058V6.32422L3.9517 14.6321C3.63019 15.0592 3.96729 15.6407 4.53645 15.6407Z"
                        fill="#D9D9D9"/>
                    <path
                        d="M23.3161 16.8254H12.686V15.6408H19.7726C20.1638 15.6408 20.4813 15.352 20.4813 14.9962C20.4813 13.0442 20.0471 11.2516 19.1905 9.66777C17.8186 7.13051 15.342 5.1875 12.686 4.1945V0.644932C12.686 0.189034 12.1781 -0.12206 11.7151 0.0463773C11.7127 0.0472366 11.7108 0.048096 11.7085 0.0485257C11.5181 0.119854 7.46073 1.63704 7.456 1.63876C6.87441 1.85618 6.87063 2.60598 7.44986 2.82856L11.2686 4.29504V16.8254H1.34742C0.911961 16.8254 0.573165 17.1791 0.648049 17.5928C1.40884 22.1315 1.28019 21.364 1.29498 21.4522C1.34752 21.7672 1.64526 22 1.99563 22H17.8365C20.2761 22 22.4574 20.7493 23.5108 18.8499C23.5143 18.8502 23.5179 18.8502 23.5215 18.8505C23.7312 18.4667 23.8942 18.0603 24.0053 17.6383C24.117 17.2138 23.7726 16.8254 23.3161 16.8254ZM12.686 6.15903C14.5786 8.30571 14.5814 11.3298 12.686 13.4795V6.15903Z"
                        fill="#D9D9D9"/>
                </svg>
            </a>

            {/*/!*OpenSea*!/*/}


            {/*<a href={'https://opensea.io/collection/meta-residence-tower'} className={`${Style.social} ${Style[`social__${flex}`]}`}>*/}

            {/*    <svg className={`${Style.social__svg} ${Style[`svg__${flex}`]}`} width="25" height="26" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
            {/*        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">*/}
            {/*                <g clip-path="url(#clip0_861_25271)">*/}
            {/*                    <path d="M4.79349 9.75608C4.79347 9.64922 4.81455 9.54342 4.85553 9.44474C4.89651 9.34606 4.95658 9.25645 5.03228 9.18107C5.10798 9.10568 5.19782 9.04599 5.29665 9.00544C5.39547 8.96489 5.50134 8.94428 5.60815 8.94477L6.95879 8.9492C7.17414 8.9492 7.38067 9.03478 7.53295 9.18711C7.68523 9.33945 7.77078 9.54605 7.77078 9.76149V14.8705C7.92293 14.8254 8.11806 14.7774 8.33183 14.7272C8.48025 14.6922 8.61252 14.6082 8.7072 14.4886C8.80189 14.3691 8.85342 14.221 8.85346 14.0685V7.73114C8.85346 7.62445 8.87445 7.5188 8.91526 7.42023C8.95606 7.32166 9.01588 7.2321 9.09128 7.15665C9.16669 7.08121 9.25621 7.02136 9.35474 6.98052C9.45327 6.93969 9.55887 6.91866 9.66552 6.91866H11.0188C11.2342 6.91869 11.4407 7.00428 11.593 7.15661C11.7452 7.30893 11.8308 7.51552 11.8308 7.73094V13.613C11.8308 13.613 12.1695 13.4758 12.4997 13.3365C12.6223 13.2846 12.7269 13.1977 12.8005 13.0868C12.874 12.9758 12.9133 12.8456 12.9134 12.7125V5.70007C12.9134 5.5934 12.9344 5.48777 12.9752 5.38922C13.016 5.29067 13.0758 5.20113 13.1512 5.1257C13.2266 5.05027 13.3161 4.99044 13.4146 4.94962C13.5132 4.90879 13.6187 4.88778 13.7254 4.88778H15.0787C15.294 4.88778 15.5006 4.97336 15.6529 5.12569C15.8052 5.27802 15.8907 5.48463 15.8907 5.70007V11.4744C17.064 10.6238 18.2531 9.60068 19.1968 8.3705C19.3337 8.19193 19.4243 7.98223 19.4605 7.76012C19.4966 7.53801 19.4773 7.31039 19.4042 7.09757C18.7657 5.23618 17.5681 3.6175 15.9751 2.46286C14.382 1.30822 12.4714 0.673979 10.5044 0.646863C5.2001 0.575584 0.816419 4.907 0.81694 10.2138C0.811732 11.893 1.24985 13.5439 2.08701 14.9994C2.20246 15.1985 2.3723 15.3605 2.57659 15.4663C2.78088 15.5722 3.01113 15.6176 3.24029 15.5971C3.49632 15.5745 3.81508 15.5427 4.19401 15.4982C4.35895 15.4794 4.51122 15.4006 4.62182 15.2768C4.73243 15.1529 4.79364 14.9927 4.79381 14.8267V9.75608" fill="white"/>*/}
            {/*                    <path d="M4.76374 17.9499C6.19025 18.988 7.87603 19.6111 9.63457 19.7503C11.3931 19.8895 13.1559 19.5393 14.7278 18.7385C16.2998 17.9377 17.6197 16.7175 18.5414 15.2129C19.4632 13.7084 19.9509 11.978 19.9506 10.2133C19.9506 9.99304 19.9404 9.77523 19.9257 9.55859C16.4319 14.771 9.98098 17.2078 4.76367 17.9501" fill="white"/>*/}
            {/*                </g>*/}
            {/*        </svg>*/}
            {/*    </svg>*/}
            {/*</a>*/}
            {/*<a href={'#'} className={`${Style.social} ${Style[`social__${flex}`]}`}>*/}
            {/*    <house className={`${Style.social__svg} ${Style[`svg__${flex}`]}`} width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
            {/*        <path*/}
            {/*            d="M4.78035 10.9502C4.78033 10.8218 4.80567 10.6946 4.85493 10.576C4.90419 10.4574 4.97639 10.3497 5.06739 10.2591C5.15838 10.1684 5.26638 10.0967 5.38517 10.0479C5.50397 9.99921 5.63122 9.97442 5.75962 9.97502L7.38314 9.98035C7.64201 9.98035 7.89027 10.0832 8.07331 10.2663C8.25636 10.4494 8.35919 10.6978 8.35919 10.9567V17.098C8.54208 17.0439 8.77664 16.9861 9.0336 16.9257C9.21201 16.8837 9.37101 16.7827 9.48482 16.639C9.59863 16.4953 9.66058 16.3173 9.66063 16.1339V8.51617C9.66062 8.38793 9.68586 8.26094 9.73491 8.14245C9.78396 8.02397 9.85586 7.91631 9.9465 7.82562C10.0371 7.73493 10.1448 7.66299 10.2632 7.6139C10.3816 7.56482 10.5086 7.53955 10.6368 7.53954H12.2635C12.5224 7.53958 12.7706 7.64246 12.9536 7.82556C13.1367 8.00867 13.2395 8.25699 13.2396 8.51594V15.5865C13.2396 15.5865 13.6467 15.4216 14.0435 15.254C14.1909 15.1917 14.3166 15.0873 14.4051 14.9539C14.4935 14.8205 14.5408 14.664 14.5409 14.504V6.07474C14.5409 5.94652 14.5661 5.81955 14.6152 5.70109C14.6642 5.58263 14.7361 5.47499 14.8267 5.38432C14.9174 5.29365 15.025 5.22173 15.1434 5.17266C15.2618 5.12359 15.3887 5.09834 15.5169 5.09834H17.1436C17.4025 5.09834 17.6508 5.2012 17.8338 5.38431C18.0169 5.56742 18.1197 5.81577 18.1198 6.07474V13.0158C19.5301 11.9933 20.9595 10.7635 22.0937 9.28472C22.2583 9.07007 22.3672 8.81801 22.4107 8.55102C22.4542 8.28403 22.431 8.01042 22.3431 7.7546C21.5756 5.51712 20.136 3.5714 18.2211 2.18346C16.3062 0.795533 14.0095 0.0331484 11.6451 0.000554289C5.26912 -0.0851266 -0.000265984 5.12144 0.000360346 11.5004C-0.00590006 13.519 0.520743 15.5034 1.52704 17.253C1.66581 17.4923 1.86998 17.687 2.11554 17.8143C2.36111 17.9415 2.63788 17.996 2.91334 17.9714C3.2211 17.9443 3.60426 17.906 4.05976 17.8525C4.25802 17.8299 4.44105 17.7352 4.574 17.5864C4.70696 17.4375 4.78054 17.245 4.78074 17.0453V10.9502"*/}
            {/*            fill="white"/>*/}
            {/*        <path*/}
            {/*            d="M4.74471 20.7996C6.45944 22.0474 8.48582 22.7965 10.5997 22.9638C12.7135 23.1311 14.8324 22.7102 16.722 21.7476C18.6116 20.785 20.1981 19.3183 21.3061 17.5097C22.4141 15.7011 23.0004 13.6211 23 11.4999C23 11.2351 22.9877 10.9733 22.97 10.7129C18.7703 16.9784 11.0161 19.9075 4.74463 20.7998"*/}
            {/*            fill="white"/>*/}
            {/*    </house>*/}
            {/*</a>*/}

            {/*Youtube*/}

            <a href={'https://www.youtube.com/@metaresidencetower'} className={`${Style.social} ${Style[`social__${flex}`]}`}>
                <svg className={`${Style.social__svg} ${Style[`svg__${flex}`]}`} width="28" height="20" viewBox="0 0 28 20" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M24.9553 0.599637C26.1546 0.922633 27.1003 1.86812 27.4231 3.06761C28.023 5.25852 27.9999 9.82533 27.9999 9.82533C27.9999 9.82533 27.9999 14.3689 27.4233 16.56C27.1003 17.7593 26.1548 18.705 24.9553 19.0277C22.7642 19.6045 13.9999 19.6045 13.9999 19.6045C13.9999 19.6045 5.25852 19.6045 3.04454 19.0049C1.84505 18.6819 0.899563 17.7362 0.576566 16.5369C0 14.3689 0 9.80226 0 9.80226C0 9.80226 0 5.25852 0.576566 3.06761C0.899349 1.86834 1.86813 0.899562 3.04433 0.576779C5.23545 0 13.9997 0 13.9997 0C13.9997 0 22.7642 0 24.9553 0.599637ZM18.4974 9.80228L11.2092 14V5.60458L18.4974 9.80228Z"
                          fill="white"/>
                </svg>
            </a>
        </motion.div>
    )
}

export default Social
