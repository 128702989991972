import React, {useState} from "react";
import Style from './Accordion.module.css';

const Accordion = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={Style.accordion}>
            <div className={Style.accordionHeader} onClick={toggleAccordion}>
                <h3 className={Style.title}>{title}</h3>
                {isOpen ? <span className={Style.open}></span> : <span className={Style.close}></span>}
            </div>
            {isOpen && <div className={Style.accordionContent}>{content}</div>}
        </div>
    )
}

export default Accordion
