import React, {useEffect, useState} from "react";
import logo from '../../images/header/Logo.svg'
import Style from './Header.module.css'
import icon from '../../images/header/header__button.webp'
import iconT from '../../images/header/button__header_T.webp'
import Social from "../Banner/Social/Social";
import Nav from "../Nav/Nav";
import CustomNav from "../Nav/CustomNav";
import {shortenAddress} from "@usedapp/core";
import SwitchWallet from "../SwitchWallet/SwitchWallet";


const Header = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const handleScrollToTop = () => {
        window.scrollTo({top: 0, behavior: "smooth"});
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden'
        } else if (!isOpen) {
            document.body.style.overflow = null
        }
    }, [isOpen])
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    if (props.account) {
        const address = props?.account
        var shortAddress = shortenAddress(props?.account)
        var shortAddress = address.substring(0, 4) + '...' + address.substring(address.length - 2)

    }

    return (
        <header className={`${Style.header__container} ${scrolled ? Style.scrolled : ''}`}>
            <div className={Style.header}>
                <img onClick={handleScrollToTop} className={Style.logo} src={logo} alt='logo' aria-label="logo"/>
                <div className={`${Style.header__nav} ${isOpen ? `${Style.active}` : ''}`}>
                    <div className={`${Style.box__nav} ${isOpen ? `${Style.active}` : ''}`}>
                        <div className={Style.container__nav}>
                            <Nav text={'About'} link={'about_us'} isOpen={isOpen} toggleMenu={toggleMenu}/>
                            <Nav text={'Features'} link={'features'} isOpen={isOpen} toggleMenu={toggleMenu}/>
                            {/*<Nav text={'Roadmap'} link={'roadmap'} isOpen={isOpen} toggleMenu={toggleMenu}/>*/}
                            <Nav text={'Get Space'} link={'mint'} isOpen={isOpen} toggleMenu={toggleMenu}/>
                            <Nav text={'FAQ'} link={'faq'} isOpen={isOpen} toggleMenu={toggleMenu}/>

                            <CustomNav text={'More'}
                                       isOpen={isOpen}
                                       toggleMenu={toggleMenu}/>
                        </div>
                        {
                            isOpen ? <Social/> : null
                        }
                    </div>
                </div>
                {
                    !props.account ?
                        <button type={'button'} className={Style.header__button} onClick={() => props.activateBrowserWallet()}>Connect wallet
                            <svg className={Style.button__svg} width="18" height="18" viewBox="0 0 18 18" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M13.1539 6.40377H18V4.15377C18 2.72224 16.8354 1.55762 15.4039 1.55762H2.59615C1.16462 1.55762 0 2.72224 0 4.15377V13.8461C0 15.2776 1.16462 16.4422 2.59615 16.4422H15.4039C16.8354 16.4422 18 15.2776 18 13.8461V11.5961H13.1539C11.7223 11.5961 10.5577 10.4314 10.5577 8.99991C10.5577 7.56839 11.7223 6.40377 13.1539 6.40377Z"
                                    fill="#222221"/>
                                <path
                                    d="M13.1537 7.44238C12.2948 7.44238 11.596 8.14115 11.596 9.00008C11.596 9.85902 12.2948 10.5578 13.1537 10.5578H17.9999V7.44242H13.1537V7.44238ZM13.846 9.51931H13.1537C12.8669 9.51931 12.6345 9.28682 12.6345 9.00008C12.6345 8.71335 12.867 8.48086 13.1537 8.48086H13.846C14.1328 8.48086 14.3652 8.71335 14.3652 9.00008C14.3652 9.28682 14.1328 9.51931 13.846 9.51931Z"
                                    fill="#222221"/>
                            </svg>
                        </button>
                        :
                        <div className={`${Style.header__button_container}  ${scrolled ? Style.scrolled : ''}`}>
                            <SwitchWallet handleToggle={props.handleToggle}
                                          onSwitch={props.onSwitch}/>
                            <div className={Style.button__box}>
                                <span
                                    className={Style.button__text}>{props.onSwitch ? Math.floor(props.balanceUSDT) : Math.floor(props.balanceMANA)}</span>
                                <div className={Style.button__flooded}>
                                    <img className={Style.button__icon} loading="lazy" src={props.onSwitch ? iconT : icon}
                                         alt={'button icon'}/>
                                    <span className={Style.text__flooded}>{shortAddress}</span>
                                </div>
                            </div>
                        </div>

                }
                <div onClick={toggleMenu} className={`${Style.burger__menu} ${isOpen ? `${Style.active}` : ''}`}>
                    <span></span>
                </div>
            </div>
        </header>
    )
}

export default Header

