import React from "react";
import {motion} from "framer-motion";
import Style from './Footer.module.css'
import Logo from "../Logo/Logo";
import Social from "../Banner/Social/Social";
import MRT from '../../images/banner/MRT.svg'
import MetaRT from '../../images/banner/MetaRt.svg'
import Nav from "../Nav/Nav";
import CustomNav from "../Nav/CustomNav";

const Footer = () => {
    const year = new Date().getFullYear();

    const Animation = {
        hidden: {
            x: -300,
            opacity: 0,
        },
        visible: {
            x: 0,
            opacity: 1,
            transition: { duration: 1.3 }
        }
    }
    const Animation2 = {
        hidden: {
            x: 300,
            opacity: 0,
        },
        visible: {
            x: 0,
            opacity: 1,
            transition: { duration: 1.3 }
        }
    }

    return (
        <motion.footer
            initial={'hidden'}
            whileInView={'visible'}
            viewport={{ once: true }}
            className={Style.footer}>
            <div className={Style.footer__container}>
                <Logo text={'Let`s make it together!'}/>
                <div className={Style.nav__container}>
                    <motion.div variants={Animation} className={Style.nav__box}>
                        <Nav text={'About'} link={'about_us'} style={'footer'}/>
                        <Nav text={'Roadmap'} link={'roadmap'} style={'footer'}/>
                        <Nav text={'FAQ'} link={'faq'} style={'footer'}/>
                    </motion.div>
                    <div className={Style.logo__container}>
                        <img className={Style.img__MRT} loading="lazy" src={MRT} alt={'Logo'}/>
                        <img className={Style.img__MetaRT} loading="lazy" src={MetaRT} alt={'Logo'}/>
                    </div>
                    <motion.div variants={Animation2} className={Style.nav__box}>
                        <Nav text={'Mint'} link={'mint'} style={'footer'}/>
                        <Nav text={'Features'} link={'features'} style={'footer'}/>
                        <CustomNav text={'More'} style={'footer'}/>
                    </motion.div>
                </div>
                <div className={Style.bottom__container}>
                    <p className={Style.footer__text}>@{year} Meta Residence Tower</p>
                    <div className={Style.social__container}>
                        <Social flex={'footer'}/>
                    </div>
                    <p className={Style.footer__text}>Privacy Policy</p>
                </div>
            </div>
        </motion.footer>
    )
}

export default Footer
