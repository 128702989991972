import React from "react";
import {motion} from "framer-motion";
import Logo from "../Logo/Logo";
import Style from './FAQ.module.css';
import Accordion from "./Accordion/Accordion";

const FAQ = () => {

    const Animation = {
        hidden: {
            x: -300,
            opacity: 0,
        },
        visible: {
            x: 0,
            opacity: 1,
            transition: { duration: 1 }
        }
    }
    const Animation2 = {
        hidden: {
            x: 300,
            opacity: 0,
        },
        visible: {
            x: 0,
            opacity: 1,
            transition: { duration: 1 }
        }
    }

    return (
        <motion.section
            initial={'hidden'}
            whileInView={'visible'}
            viewport={{ once: true }}
            className={Style.faq} id={'faq'}>
            <div className={Style.faq__container}>
                <Logo text={'FAQ'}/>
                <div className={Style.container}>
                    <motion.div  variants={Animation} className={Style.box} viewport={{ once: true }}> 
                        <Accordion title={'What exactly is the Meta Residence Tower (MRT) project?'}
                                   content={
                                       <>
                                           <p>The MRT project aims to address challenges faced by Decentraland users
                                               when creating or
                                               accessing customized virtual spaces. It consists of a virtual skyscraper
                                               offering 10,000 NFT apartments.</p>
                                           <p>It allows users to interact with virtual spaces cost-effectively and
                                               user-friendly without the need for code or developers.</p>
                                       </>}/>


                        <Accordion title={'How do I acquire an apartment in the MRT?'}
                                   content={<>
                                       <p>You can purchase an NFT key either on our website or in the MRT building
                                           itself.</p>
                                       <p>This key grants you access to an apartment in the tower.</p>
                                   </>}/>
                        <Accordion title={'What types of layouts are available in the MRT apartments?'}
                                   content={<>
                                       <p><b>The MRT offers four types of apartment layouts</b>: Studio, 1BHK, 2BHK, and
                                           Penthouse.</p>
                                       <p>There is also the extra option to convert your apartment into an open
                                           space.</p>
                                   </>}/>
                        <Accordion title={'What do I receive upon purchasing an MRT apartment?'}
                                   content={<>
                                       <b>When you purchase an MRT apartment, you receive the following:</b>
                                       <p>1. The apartment itself with an NFT key for exclusive access.</p>
                                       <p>2. A free furniture editor that comes with 700+ FREE items, including
                                           furniture, walls, ceilings, and floors, allowing you to customize your
                                           space.</p>
                                       <p>3. A free smart item, the Boombox, enhances your in-apartment experience.</p>
                                       <p>4. Free smart-items custom paintings to personalize your space further.</p>
                                       <p>5. Free public-mode access feature that you can activate as per your
                                           needs.</p>
                                       <p>6. Free pin-code access feature that adds an additional layer of security.</p>
                                       <p>7. For additional smart items and upgrades, you can make purchases from our
                                           shop.</p>
                                       <p>8. The first 200 users will also receive legendary slippers and a branded robe
                                           wearable as a special gift.</p>
                                       <p></p>
                                   </>}/>
                    </motion.div>
                    <motion.div variants={Animation2} className={Style.box} viewport={{ once: true }}>
                        <Accordion
                            title={'What are the interactive Smart Items and Upgrades available in the apartments?'}
                            content={
                                <>
                                    <p>Upon purchasing an MRT apartment, you'll receive a free Boombox music player and
                                        custom painting frames.</p>
                                    <p>For those seeking to enhance their apartment's functionality and ambiance
                                        further, we offer a variety of optional
                                        smart items and upgrades available for a nominal fee:</p>
                                    <p>1. Boombox: A music player that the apartment owner can turn on. Music plays only
                                        for those inside the apartment.</p>
                                    <p>2. Picture Frames and Large Banners: The apartment owner can upload any external
                                        images.</p>
                                    <p>3. Dance Floors: Comes with auto-dancing control for an immersive experience.</p>
                                    <p>4. Social Media Links: The owner can set external links to their Discord,
                                        Telegram, Twitter, and Website.</p>
                                    <p>5. Chroma Key, Multipurpose Screen: A feature for a variety of uses.</p>
                                    <p>6. Animated Light Music, Disco Balls: For creating an entertaining
                                        atmosphere.</p>
                                    <p>7. NFT Frames: These can display NFTs with a link to OpenSea.</p>
                                    <p>8. Donation Box: The owner can set up a box for donations in MANA and USDT.</p>
                                    <p>9. Video Screens: The owner can stream audio and video content directly to the
                                        apartment. There is also a paid CDN feature for broadcasting from our video
                                        servers.</p>
                                    <p>10. Programmable Pin-code Secured Safe: An additional layer of security for your
                                        valuable virtual items.</p>
                                    <p>11. Pin-code Door: A useful feature for game mechanics within your space.</p>
                                    <p>12. Programmable Noticeboard: Display text or images of your choosing.</p>
                                    <p>13. Door Banner & Name: Personalize your entrance in the corridor and public
                                        dashboard.</p>
                                    <p>14. UI - Clickable Banner: Enhance your user interface with interactive
                                        banners.</p>
                                    <p>15. Lock System for Smart-Items: Additional security for your smart-items.</p>
                                    <p>16. CCTV: A separate upgrade related to access. The owner can see who has been in
                                        the apartment, how many times, and when their last visit was.</p>
                                    <p>Investing in these features allows for a richer, more personalized experience
                                        within your MRT apartment.</p>
                                </>
                            }/>
                        <Accordion title={'How do I control access to my apartment?'}
                                   content={
                                       <>
                                           <p>Access control is completely in your hands. Only the user with the NFT key
                                               can enter by default.</p>
                                           <p>However, you can customize access settings.</p>
                                           <p>For access control, we offer the following:</p>
                                           <p>1. Public Mode: Anyone can enter the apartment.</p>
                                           <p>2. Pin Code Access: Only pin code holders can enter the apartment.</p>
                                           <p>3. Access by Wearables: The owner can specify addresses for up to 3
                                               Wearables items. Users wearing these Wearables items can enter the
                                               apartment.</p>
                                           <p>4. Access by NFTs: The owner can specify addresses for up to 3
                                               NFT(Ethereum/Polygon) addresses. Users holding these NFT items can enter
                                               the apartment.</p>
                                           <p>5. Access for Interior Designers: The owner can specify an address with
                                               access to all apartment design functions.</p>
                                           <p>6. Access for Payment: The owner can set up payment for entry to the
                                               apartment.</p>
                                           <p>7. Membership: The owner can sell permanent access to their space.</p>
                                       </>
                                   }/>
                        <Accordion title={'What currencies can I use for transactions in the MRT?'}
                                   content={<>
                                       <p>We facilitate transactions in two currencies on POLYGON CHAIN: USDT and MANA
                                           at the current rate.</p>
                                       <p>Our own meta-transaction server enables gasless transactions, making payments
                                           seamless.</p>
                                   </>}/>
                        <Accordion title={'Can I host events or display exhibits in my MRT apartment?'}
                                   content={
                                       <>
                                           <p>Yes, the MRT project provides a versatile space for various activities
                                               such as mini-games, quests, NFT galleries, fashion shows, parties with
                                               streaming, parkour, paid events, business cards, shops, and meet-ups with
                                               friends.</p>
                                       </>
                                   }/>
                    </motion.div>
                </div>
            </div>

        </motion.section>
    )
}

export default FAQ
