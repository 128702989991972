import React, {useState} from "react";
import {motion} from "framer-motion";
import Style from './TypesOfFlats.module.css'
import Logo from "../Logo/Logo";
import button from '../../images/AboutUs/AboutUs__button.svg'
import Container from "./Сontainer/Сontainer";

const TypesOfFlats = ({priceInMANA}) => {

    const [currentName, setCurrentName] = useState(1);
    const name = [
        'Studio',
        '1BHK',
        '2BHK',
        'Penthouse',
    ];

    function array() {
        if (currentName === 1) {
            return <Container priceInMANA={priceInMANA} name={'Studio'} price={'69'} textTitle={'Cozy Studio.'} text={<>
                <b>When you purchase an MRT apartment, you receive the following:</b>
                <p>- The apartment itself with an NFT key for exclusive access.</p>
                <p>- A free furniture editor that comes with 700+ FREE items, including furniture, walls, ceilings, and
                    floors, allowing you to customize your space.</p>
                <p>- A free smart item, the Boombox, enhances your in-apartment experience.</p>
                <p>- Free smart-items custom paintings to personalize your space further.</p>
                <p>- Free public-mode access feature that you can activate as per your needs.</p>
                <p>- Free pin-code access feature that adds an additional layer of security.</p>
                <p>- The first 200 users will also receive legendary slippers and a branded robe wearable as a special
                    gift.</p>
                <p>For additional smart items and upgrades, you can make purchases from our shop.</p>
            </>}/>;
        }
        if (currentName === 2) {
            return <Container name={'1BHK'} price={'129'} textTitle={'Comfortable 1BHK Apartment.'} text={
                <>
                    <b>When you purchase an MRT apartment, you receive the following:</b>
                    <p>- The apartment itself with an NFT key for exclusive access.</p>
                    <p>- A free furniture editor that comes with 700+ FREE items, including furniture, walls, ceilings,
                        and floors, allowing you to customize your space.</p>
                    <p>- A free smart item, the Boombox, enhances your in-apartment experience.</p>
                    <p>- Free smart-items custom paintings to personalize your space further.</p>
                    <p>- Free public-mode access feature that you can activate as per your needs.</p>
                    <p>- Free pin-code access feature that adds an additional layer of security.</p>
                    <p>- The first 200 users will also receive legendary slippers and a branded robe wearable as a
                        special gift.</p>
                    <p>For additional smart items and upgrades, you can make purchases from our shop.</p>
                </>
            }/>;
        }
        if (currentName === 3) {
            return <Container name={'2BHK'} price={'189'} textTitle={'Spacious 2BHK Apartment.'} text={
                <>
                    <b>When you purchase an MRT apartment, you receive the following:</b>
                    <p>- The apartment itself with an NFT key for exclusive access.</p>
                    <p>- A free furniture editor that comes with 700+ FREE items, including furniture, walls, ceilings,
                        and floors, allowing you to customize your space.</p>
                    <p>- A free smart item, the Boombox, enhances your in-apartment experience.</p>
                    <p>- Free smart-items custom paintings to personalize your space further.</p>
                    <p>- Free public-mode access feature that you can activate as per your needs.</p>
                    <p>- Free pin-code access feature that adds an additional layer of security.</p>
                    <p>- The first 200 users will also receive legendary slippers and a branded robe wearable as a
                        special gift.</p>
                    <p>For additional smart items and upgrades, you can make purchases from our shop.</p>
                </>
            }/>;
        }
        if (currentName === 4) {
            return <Container name={'Penthouse'} price={'350'}
                              textTitle={'Luxury two-story Penthouse placed from 90 to 99 floor on both sectors.'}
                              text={<>
                                  <b>When you purchase an MRT apartment, you receive the following:</b>
                                  <p>- The apartment itself with an NFT key for exclusive access.</p>
                                  <p>- A free furniture editor that comes with 700+ FREE items, including furniture,
                                      walls, ceilings, and floors, allowing you to customize your space.</p>
                                  <p>- A free smart item, the Boombox, enhances your in-apartment experience.</p>
                                  <p>- Free smart-items custom paintings to personalize your space further.</p>
                                  <p>- Free public-mode access feature that you can activate as per your needs.</p>
                                  <p>- Free pin-code access feature that adds an additional layer of security.</p>
                                  <p>- The first 200 users will also receive legendary slippers and a branded robe
                                      wearable as a special gift.</p>
                                  <p>For additional smart items and upgrades, you can make purchases from our shop.</p>
                              </>
                              }/>;
        }
        return array;
    }

    function header () {
        if (currentName === 1) {
            return  'Studio';
        }
        if (currentName === 2) {
            return  '1BHK';
        }
        if (currentName === 3) {
            return  '2BHK';
        }
        if (currentName === 4) {
            return  'Penthouse';
        }
        return header;
    }

    const Animation = {
        hidden: {
            y: -50,
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: {duration: 1}
        }
    }

    return (
        <motion.section className={Style.flats}
                        initial={'hidden'}
                        whileInView={'visible'}
                        viewport={{ once: true }}>
            <div className={Style.container}>
                <Logo text={'4 types of flats'}/>
                <motion.p variants={Animation} className={Style.flats__subtitle}>There are four types of Apartments</motion.p>
                <div className={Style.box}>
                    <motion.div variants={Animation} className={Style.nav__container} viewport={{ once: true }}>
                        {
                            name.map((item, index) => (
                                <div key={index} className={`${Style.nav} ${currentName === index + 1 && Style.active}`}
                                     onClick={() => setCurrentName(index + 1)}>
                                    {item}
                                </div>
                            ))
                        }
                    </motion.div>
                    <div className={`${Style.nav} ${Style.nav__768px}`}>{header()}</div>
                    <div className={Style.box__flats}>
                        <button className={Style.button} onClick={() => currentName === 1 ? setCurrentName(4) : setCurrentName(currentName - 1)}><img
                            className={Style.back__img} src={button} alt={'icon'}/></button>
                        <div className={Style.center__container}>{array()}</div>
                        <button className={Style.button} onClick={() => currentName === 4 ? setCurrentName(1) : setCurrentName(currentName + 1)}><img
                            className={Style.forward__img} src={button} alt={'icon'}/></button>
                    </div>
                </div>
            </div>
        </motion.section>
    )
}

export default TypesOfFlats
