import React from "react";
import {motion} from "framer-motion";
import Style from './Switch.module.css'

const Switch = ({isEnabled, toggleSwitch}) => {

    const Animation = {
        hidden: {
            y: -50,
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: {duration: 1}
        }
    }

    return (
        <motion.div  initial={'hidden'}
                     whileInView={'visible'}
                     viewport={{ once: true }}
                     variants={Animation}
                     className={Style.switch}>
            <span className={Style.text}>Sector</span>
            <div className={Style.switchContainer}>
                <input
                    type="checkbox"
                    className={Style.switchInput}
                    checked={isEnabled}
                    onChange={toggleSwitch}
                    id="switch"
                />
                <label htmlFor="switch" className={Style.switchLabel}>
                    <span className={Style.switchInner}></span>
                    <span className={`${Style.sectorA} ${isEnabled ? '' : Style.colorA}`}>A</span>
                    <span className={`${Style.sectorB} ${isEnabled ? '' : Style.colorB}`}>B</span>
                </label>
            </div>
        </motion.div>
    )
}

export default Switch
