import React from "react";
import {motion} from "framer-motion";
import Style from  './Floor.module.css'
const Floor = ({floorDensity, isEnabled, current, selectedCard, updateStep}) => {
    let myArray = Array.from({length: 100}, (_, i) => i + 1);
    
    function getDensity(sectorVal, i) {
        if(floorDensity[sectorVal][i] <= 0){
            return ''
        }

        else if(floorDensity[sectorVal][i] <= 10){
            return 'floorbutton_greenTexture'
        }
            
        else if(floorDensity[sectorVal][i] <= 25){
            return 'floorbutton_yellowTexture'
        }

        else if(floorDensity[sectorVal][i] < 50){
            return 'floorbutton_brownTexture'
        }

        else if(floorDensity[sectorVal][i] === 50){
            return 'floorbutton_redTexture'
        }
    }

    const Animation = {
        hidden: {
            x: -200,
            opacity: 0,
        },
        visible: {
            x: 0,
            opacity: 1,
            transition: {duration: 1}
        }
    }
    
    return (
        <motion.section initial={'hidden'}
                        whileInView={'visible'}
                        viewport={{ once: true }}
                        variants={Animation}
                        className={Style.floor}>
            <div className={Style.floor__container}>
                <p className={Style.text}>SECTOR</p>
                <div className={Style.floors__container}>
                    {
                        myArray.map((num , index) => (
                            <div onClick={() => updateStep(index + 1)}  key={index}
                                 className={`${Style.box} ${current === index + 1 && Style.box__active}
                                 ${selectedCard === index + 1 && Style.box__active} ${floorDensity && Style[getDensity((isEnabled ? 1 : 0), index)]}`}>{index}</div>
                        ))
                    }
                </div>
                <p className={Style.text}>{isEnabled ? 'B' : 'A'}</p>
            </div>
        </motion.section>
    )
}

export default Floor
