import React, {useState} from "react";
import {motion} from "framer-motion";
import Style from './Features.module.css'
import Logo from "../Logo/Logo";
import FeaturesAccordion from "./FeaturesAccordion/FeaturesAccordion";
import {data} from "./data";
const Features = () => {
    const [selectorFeatures, setSelectorFeatures] = useState([data[0].url])
    const [isOpen, setIsOpen] = useState(1);

    const toggleAccordion = (item) => {
        setSelectorFeatures(item.url)
        setIsOpen(item.id);
    };

    const Animation = {
        hidden: {
            x: 200,
            opacity: 0,
        },
        visible: {
            x: 0,
            opacity: 1,
            transition: { duration: 1 }
        }
    }

    return (
        <motion.section className={Style.features}
                        initial={'hidden'}
                        whileInView={'visible'}
                        viewport={{ once: true }}
                        id={'features'}>
            <div className={Style.features__box}>
                <Logo text={'Features'}/>
                <div className={Style.features__container}>
                    <p className={Style.features__text}>The apartments have many features that will make your stay in
                        Decentraland unique. Create your own
                        home in the Metaverse, and decorate with the functional editor. More than 500 pieces of
                        furniture,
                        decorations of floors, walls, and ceilings. Advance access to the apartment. Private, Public,
                        via
                        pin code, wearables access.</p>
                    <motion.div variants={Animation} className={Style.container} viewport={{ once: true }}>
                        <div className={Style.container__accordion}>
                            {
                                data.map((item, index) => (
                                    <div className={Style.accordion} onClick={() => toggleAccordion(item)} key={index}>
                                        <FeaturesAccordion item={item} isOpen={isOpen}/>
                                    </div>
                                ))
                            }
                        </div>
                        <div className={Style.container__img}>
                            <img className={Style.features__img} loading="lazy" src={selectorFeatures} alt={'Features img'}/>
                        </div>
                    </motion.div>
                </div>
            </div>
        </motion.section>
    )
}

export default Features
