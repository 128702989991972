import React from "react";
import Style from './Preloaders.module.css'
import preloaders from '../../images/Preloader.svg'
const Preloaders = () => {
    return <div className={Style.box}>
        <img className={Style.preloader} loading="lazy" src={preloaders} alt={'preloader'}/>
    </div>
}

export default Preloaders
