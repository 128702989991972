import React, {useRef, useState} from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Navigation, Thumbs} from 'swiper/core';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import Logo from "../Logo/Logo";
import Style from './ResidentsGallery.module.css'
import {images} from "./images";
import buttonImg from '../../images/ResidentsGallery/button.svg'

SwiperCore.use([Navigation, Thumbs]);

const ResidentsGallery = () => {
    const [selectedImage, setSelectedImage] = useState(0);
    const thumbsSwiperRef = useRef(null);
    return (
        <section className={Style.container}>
            <Logo text={'Residents Gallery'}/>
            <div className={Style.gallery_box}>
                <Swiper
                    style={{'--swiper-navigation-color': '#fff', '--swiper-pagination-color': '#fff'}}
                    loop={true}
                    spaceBetween={10}
                    slidesPerView={1}
                    navigation={{
                        nextEl: `.${Style.nextSlideButton}`,
                        prevEl: `.${Style.prevSlideButton}`
                    }}
                    thumbs={{swiper: thumbsSwiperRef.current}}
                    modules={[Navigation, Thumbs]}
                    onSlideChange={(swiper) => {
                        setSelectedImage(swiper.realIndex)
                        thumbsSwiperRef.current.slideToLoop(swiper.realIndex);
                    }}
                    className={Style.mySwiper2}
                >
                    {images.map((image, index) => (
                        <SwiperSlide key={index}>
                            <img src={image.url} loading="lazy" alt={`Slide ${index + 1}`}
                                 className={Style.mySwiper2_img}/>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <button className={Style.nextSlideButton}><img src={buttonImg} alt={'button'}/></button>
                <button className={Style.prevSlideButton}><img src={buttonImg} alt={'button'}/></button>
                <Swiper
                    loop={true}
                    spaceBetween={10}
                    slidesPerView={3}
                    freeMode={true}
                    watchSlidesProgress={true}
                    onSwiper={swiper => {
                        thumbsSwiperRef.current = swiper;
                    }}
                    modules={[Thumbs]}
                    className={Style.mySwiper}
                >
                    {images.map((image, index) => (
                        <SwiperSlide key={index}>
                            <img src={image.url} loading="lazy" alt={`Thumbnail ${index + 1}`}
                                 className={`${Style.mySwiper_img} ${selectedImage === index ? Style.active : ''}`}/>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div className={Style.container_circle}>
                {
                    images.map((_ ,index) => (
                        <div key={index} className={`${Style.circle} ${selectedImage === index ? Style.active : ''}`}></div>
                    ))
                }
            </div>
        </section>
    )
}

export default ResidentsGallery
