import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import {ChainId, DAppProvider} from '@usedapp/core';


const config = {
    supportedChains: [ChainId.Polygon, ChainId.Mumbai], // 137, 80001
    readOnlyChainId: ChainId.Mumbai,
    readOnlyUrls: {
        [ChainId.Polygon]: 'https://polygon-rpc.com',
        // [ChainId.Mumbai]: 'https://rpc.ankr.com/polygon_mumbai	',
    },
};


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <DAppProvider config={config}>
            <App/>
        </DAppProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your App, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
