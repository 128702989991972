//TODO: Refactor all this into resources global file
import svg1 from '../../images/Features/svg1.svg'
import svg3 from '../../images/Features/svg3.svg'
import image1 from '../../images/Features/600+items.webp'
import image2 from '../../images/Features/BuildeMode.webp'
import image3 from '../../images/Features/Guests.webp'
import smarts from '../../images/Features/smarts.webp'
import upgrades from '../../images/Features/upgrades.webp'
import upgrades_icon from '../../images/Features/upgrades.webp'
import public_flat from '../../images/Features/public_flats.webp'
import heat_map from '../../images/Features/heat_map.webp'
import pay from '../../images/Features/pay.webp'

import boombox from "../../images/Features/smart_items/boombox.webp"
import banner from "../../images/Features/smart_items/banner.webp"
import discord from "../../images/Features/smart_items/discord.webp"
import twitter from "../../images/Features/smart_items/twitter.webp"
import website from "../../images/Features/smart_items/website.webp"
import telegram from "../../images/Features/smart_items/telegram.webp"
import image_note from "../../images/Features/smart_items/image_note.webp"


import pinsafe from "../../images/Features/smart_items/pinsafe.webp"
import txtnote from "../../images/Features/smart_items/txtnote.webp"
import customnft from "../../images/Features/smart_items/customnft.webp"
import dancefloor from "../../images/Features/smart_items/dancefloor.webp"
import streamtv from "../../images/Features/smart_items/streamtv.webp"
import custpainting from "../../images/Features/smart_items/custpainting.webp"
import pindoor from "../../images/Features/smart_items/pindoor.webp"


import aquarium from "../../images/Features/premium_items/aquarium.webp"
import cake from "../../images/Features/premium_items/cake.webp"
import chroma from "../../images/Features/premium_items/chroma.webp"
import discoball from "../../images/Features/premium_items/discoball.webp"
import spotlight from "../../images/Features/premium_items/spotlight.webp"


import aquarium_icon from "../../images/Features/aquarium.webp"
import boombox_icon from "../../images/Features/boombox.webp"
import banner_icon from "../../images/Features/banner.webp"
import discord_icon from "../../images/Features/discord.webp"
import twitter_icon from "../../images/Features/twitter.webp"
import website_icon from "../../images/Features/website.webp"
import telegram_icon from "../../images/Features/telegram.webp"
import image_note_icon from "../../images/Features/image_note.webp"

import pinsafe_icon from "../../images/Features/pnsafe.webp"
import txtnote_icon from "../../images/Features/txtnote.webp"
import customnft_icon from "../../images/Features/customnft.webp"
import dancefloor_icon from "../../images/Features/dancefloor.webp"
import streamtv_icon from "../../images/Features/streamtv.webp"
import custpainting_icon from "../../images/Features/custpainting.webp"
import pindoor_icon from "../../images/Features/pincode.webp"

import cake_icon from "../../images/Features/cake.webp"
import chroma_icon from "../../images/Features/chroma.webp"
import discoball_icon from "../../images/Features/discoball.webp"
import spotlight_icon from "../../images/Features/spotlight.webp"

import public_flat_icon from "../../images/Features/Public_Flats_Dashboard.webp"
import heat_map_icon from "../../images/Features/Heatmap_Users.webp"
import furniture_icon from "../../images/Features/Heatmap_Users.webp"
import pay_icon from "../../images/Features/Gasless_Transactions.webp"
import access_icon from "../../images/Features/Gasless_Transactions.webp"


import cctv from "../../images/Features/upgrades/cctv.webp"
import designeraccess from "../../images/Features/upgrades/designeraccess.webp"
import doorbanner from "../../images/Features/upgrades/doorbanner.webp"
import doorname from "../../images/Features/upgrades/doorname.webp"
import doorpic from "../../images/Features/upgrades/doorpic.webp"
import nftscanner from "../../images/Features/upgrades/nftscanner.webp"
import openspace from "../../images/Features/upgrades/openspace.webp"
import paidentry from "../../images/Features/upgrades/paidentry.webp"
import pincode from "../../images/Features/upgrades/pincode.webp"
import publicflat from "../../images/Features/upgrades/publicflat.webp"
import uibanner from "../../images/Features/upgrades/uibanner.webp"
import wearablescanner from "../../images/Features/upgrades/wearablescanner.webp"

import cctv_icon from "../../images/Features/cctv.webp";
import designeraccess_icon from "../../images/Features/Designer Access.webp";
import doorbanner_icon from "../../images/Features/Door Banner.webp";
import doorname_icon from "../../images/Features/Door Name.webp";
import doorpic_icon from "../../images/Features/Door Avatar.webp";
import nftscanner_icon from "../../images/Features/NFT Scanner.webp";
import openspace_icon from "../../images/Features/Open Space.webp";
import paidentry_icon from "../../images/Features/Paid Entry.webp";
import pincode_icon from "../../images/Features/Pin Code Lock.webp";
import publicflat_icon from "../../images/Features/Public Flat.webp";
import uibanner_icon from "../../images/Features/UI Banner.webp";
import wearablescanner_icon from "../../images/Features/Wearable Scanner.webp";


/* Uncomment when designer actually gets us the icons

import cctv_icon from "../../images/Features/svg3.svg";
import designeraccess_icon from "../../images/Features/svg3.svg";
import doorbanner_icon from "../../images/Features/svg3.svg";
import doorname_icon from "../../images/Features/svg3.svg";
import doorpic_icon from "../../images/Features/svg3.svg";
import nftscanner_icon from "../../images/Features/svg3.svg";
import openspace_icon from "../../images/Features/svg3.svg";
import paidentry_icon from "../../images/Features/svg3.svg";
import pincode_icon from "../../images/Features/svg3.svg";
import publicflat_icon from "../../images/Features/svg3.svg";
import uibanner_icon from "../../images/Features/svg3.svg";
import wearablescanner_icon from "../../images/Features/svg3.svg";

*/


export const data = [
    {
        id: 1,
        title: 'Builder mode',
        subtitle: <>
            <p>
                Move, create, open your creativity
            </p>
        </>,
        icon: svg1,
        url: image2

    },
    {
        id: 2,
        title: '700+ Items of furniture',
        subtitle:
            <><p>
                Each apartment includes an iinterior design constructor with more than 700 furniture items (from bedroom
                to bathroom),
                along with options for ceilings, floors, and wallpapers.
            </p>
                <p>
                    Users can create unique furniture arrangements with this tool.
                </p>
            </>,
        icon: furniture_icon,
        url: image1
    },
    {
        id: 3,
        title: 'Access Features',
        subtitle:
            <>
                <p>
                    By default, only the user with the NFT key can enter the apartment.
                </p>
                <p>
                    However, the owner can set various access levels:
                </p>
                <p>- Public.</p>
                <p>- Pin code</p>
                <p>- Access by Wearables</p>
                <p>- Access by NFTs</p>
                <p>- Access for interior designers</p>
                <p>- Access for payment</p>
                <p>- Membership</p>
                <p>- CCTV: The owner can see who has been in the apartment, how many times, and when their last visit
                    was.</p>
            </>,
        icon: access_icon,
        url: image3
    },
    {
        id: 4,
        title: 'Smart Items',
        subtitle:
            <>
                <p>- Boombox</p>
                <p>- Picture frames and large banners</p>
                <p>- Dance floors with auto-dancing control</p>
                <p>- Discord, Telegram, Twitter, Website links</p>
                <p>- Chroma key, multipurpose screen</p>
                <p>- Animated light music, disco balls.</p>
                <p>- NFT frames</p>
                <p>- Donation box</p>
                <p>- Few types of Video Screens</p>
                <p>- There is also a paid CDN feature for broadcasting from our video servers.</p>
                <p>- Programmable pin-code secured safe</p>
                <p>- Pin-code door for game mechanics</p>
                <p>- Programmable noticeboard with text or images.</p>
            </>,
        icon: svg3,
        url: smarts
    },
    {
        id: 5,
        title: 'Upgrades',
        subtitle:
            <>
                <p>There is a few extra upgrades you can purchase:</p>
                <p>- Remove all doors, and make an Open Space</p>
                <p>- NFT Scanner</p>
                <p>- Wearables Scanner</p>
                <p>- CCTV</p>
                <p>- Payable access</p>
                <p>- Membership access</p>
                <p>- UI Banner</p>
                <p>- Door Banner & Name in the corridor and public dashboard.</p>
            </>,
        icon: upgrades_icon,
        url: upgrades
    },
    {
        id: 6,
        title: 'Gasless Transactions in MANA or USDT',
        subtitle:
            <>
                <p>Gasless transactions have been implemented for purchasing apartments, upgrades, and smart items both
                    on the website and within Decentraland.</p>
                <p>We have our own meta-transaction server.</p>
                <p>Payments can be made in two currencies:</p>
                <p> USDT and MANA at the current rate.</p>
            </>,
        icon: pay_icon,
        url: pay
    },
    {
        id: 7,
        title: 'Public Flats Dashboard',
        subtitle: <>
            <p>
                Dashboard of public flats, with a visitors counter and real-time online user counter
            </p>
        </>,
        icon: public_flat_icon,
        url: public_flat
    },
    {
        id: 8,
        title: 'Heat Map',
        subtitle:
            <>
                <p>
                    Choose your neighbours
                </p>
            </>,
        icon: heat_map_icon,
        url: heat_map
    },
    {
        id: 9,
        title: 'Boombox',
        subtitle:
            <>
                <p>A music player that can be turned on by the apartment owner.</p>
                <p>Music plays only for those inside the apartment.</p>
            </>,
        icon: boombox_icon,
        url: boombox
    },
    {
        id: 10,
        title: 'Banner',
        subtitle:
            <>
                <p>The apartment owner can upload any external images.</p>
            </>,
        icon: banner_icon,
        url: banner
    },
    {
        id: 11,
        title: 'Discord Link',
        subtitle:
            <>
                <p>
                    The owner can set external links to their Discord Server.
                </p>
            </>,
        icon: discord_icon,
        url: discord
    },
    {
        id: 12,
        title: 'Twitter Link',
        subtitle:
            <>
                <p>The owner can set external links to their Twitter.</p>
            </>,
        icon: twitter_icon,
        url: twitter
    },
    {
        id: 13,
        title: 'Website Link',
        subtitle:
            <>
                <p>
                    The owner can set external links to their Website.
                </p>
            </>,
        icon: website_icon,
        url: website
    },
    {
        id: 14,
        title: 'Telegram Link',
        subtitle:
            <>
                <p>
                    The owner can set external links to their Telegram
                </p>
            </>,
        icon: telegram_icon,
        url: telegram
    },
    {
        id: 15,
        title: 'Image Note',
        subtitle:
            <>
                <p>
                    A customizable small-scale image, perfect for decoration and adding atmosphere.
                </p>
            </>,
        icon: image_note_icon,
        url: image_note
    },
    {
        id: 16,
        title: 'Pin-Code Safe',
        subtitle:
            <>
                <p>
                    Programmable pin-code secured safe, perfect for hidden messages and escape rooms.
                </p>
            </>,
        icon: pinsafe_icon,
        url: pinsafe
    },
    {
        id: 17,
        title: 'Text Note',
        subtitle:
            <>
                <p>
                    A customizable sticky note to give flare to your house and provide clues in interactive experiences.
                </p>
            </>,
        icon: txtnote_icon,
        url: txtnote
    },
    {
        id: 18,
        title: 'Custom NFT Frame',
        subtitle:
            <>
                <p>
                    A stylish frame, that allows owners and designers to display any Ethereum NFT by simply providing an
                    OpenSea link.
                </p>
            </>,
        icon: customnft_icon,
        url: customnft
    },
    {
        id: 19,
        title: 'Dance Floor',
        subtitle:
            <>
                <p>Technicolor dance floor, making everyone who steps on it dance automatically.</p>
                <p>Automatic dancing can be disabled with a toggle.</p>
            </>,
        icon: dancefloor_icon,
        url: dancefloor
    },
    {
        id: 20,
        title: 'Streaming TV',
        subtitle:
            <>
                <p>The owner can stream audio and video content directly to the apartment.</p>
            </>,
        icon: streamtv_icon,
        url: streamtv
    },
    {
        id: 21,
        title: 'Custom Painting',
        subtitle:
            <>
                <p>
                    A bigger sibling of Image Note, Custom Painting allows displaying any image within your apartmentm,
                    you just need a link to it.
                </p>
            </>,
        icon: custpainting_icon,
        url: custpainting
    },
    {
        id: 22,
        title: 'Pin-Coded Door',
        subtitle:
            <>
                <p>A code-locked door, allowing the owner to restrict access to certain parts of their apartment or to
                    create puzzle progression in interactive experiences they create.</p>
            </>,
        icon: pindoor_icon,
        url: pindoor
    },
    {
        id: 23,
        title: 'Aquarium',
        subtitle:
            <>
                <p>
                    A lovely aquarium, filled with animated fishy to add lively spark to your apartment.
                </p>
            </>,
        icon: aquarium_icon,
        url: aquarium
    },
    {
        id: 24,
        title: 'Cake',
        subtitle:
            <>
                <p>
                    A festive (and, sadly, currently inedible) decoration for hosting parties and get-togethers.
                </p>
                <p>
                    We can guarantee that this one is not a lie.
                </p>
            </>,
        icon: cake_icon,
        url: cake
    },
    {
        id: 25,
        title: 'Chroma Key/Green Screen',
        subtitle:
            <>
                <p>Allows to turn your apartment into a full-blown CGI studio within decentraland.</p>
                <p>The screen changes colours on demand.</p>
            </>,
        icon: chroma_icon,
        url: chroma
    },
    {
        id: 26,
        title: 'Disco Ball',
        subtitle:
            <>
                <p>
                    A dazzling disco ball that fills your apartment with colorful lights, creating a vibrant atmosphere.
                </p>
            </>,
        icon: discoball_icon,
        url: discoball
    },
    {
        id: 27,
        title: 'Spotlight',
        subtitle:
            <>
                <p>
                    A powerful spotlight that illuminates your apartment with dynamic and vibrant colors, perfect for
                    creating an energetic party ambiance.
                </p>
            </>,
        icon: spotlight_icon,
        url: spotlight
    },

    {
        id: 28,
        title: 'CCTV',
        subtitle:
            <>
                <p>
                    A surveillance camera system that allows you to monitor visitors to your apartment.
                </p>
            </>,
        icon: cctv_icon,
        url: cctv
    },
    {
        id: 29,
        title: 'Designer Access',
        subtitle:
            <>
                <p>
                    Allows to unlock builder functionality for another user to allow them to decorate your apartment for
                    you.
                </p>
            </>,
        icon: designeraccess_icon,
        url: designeraccess
    },
    {
        id: 30,
        title: 'Door Banner',
        subtitle:
            <>
                <p>
                    Customize your apartment entrance with a personalized banner that reflects your style and
                    personality.
                </p>
            </>,
        icon: doorbanner_icon,
        url: doorbanner
    },
    {
        id: 31,
        title: 'Door Name',
        subtitle:
            <>
                <p>
                    Display your name or a unique label above your apartment door, making it easier for visitors to
                    identify.
                </p>
            </>,
        icon: doorname_icon,
        url: doorname
    },
    {
        id: 32,
        title: 'Door Avatar',
        subtitle:
            <>
                <p>Add an image of your DCL avatar to your apartment door, adding a personal touch to your space.</p>
            </>,
        icon: doorpic_icon,
        url: doorpic
    },
    {
        id: 33,
        title: 'NFT Scanner',
        subtitle:
            <>
                <p>Allow users in posession of a specific NFT to access your apartment.</p>
            </>,
        icon: nftscanner_icon,
        url: nftscanner
    },
    {
        id: 34,
        title: 'Open Space',
        subtitle:
            <>
                <p>
                    Expand the available space in your apartment by removing walls and creating an open and spacious
                    layout.
                </p>
            </>,
        icon: openspace_icon,
        url: openspace
    },
    {
        id: 35,
        title: 'Paid Entry',
        subtitle:
            <>
                <p>
                    Charge visitors an entry fee to your apartment, allowing you to monetize your space and generate
                    income.
                </p>
            </>,
        icon: paidentry_icon,
        url: paidentry
    },
    {
        id: 36,
        title: 'Pin Code Lock',
        subtitle:
            <>
                <p>Enhance the security of your apartment by installing a pin code lock for your entrance door.</p>
            </>,
        icon: pincode_icon,
        url: pincode
    },
    {
        id: 37,
        title: 'Public Flat',
        subtitle:
            <>
                <p>
                    Transform your apartment into a public space for hosting events, exhibitions, or social gatherings.
                </p>
            </>,
        icon: publicflat_icon,
        url: publicflat
    },
    {
        id: 38,
        title: 'UI Banner',
        subtitle:
            <>
                <p>
                    Display a customizable banner right on top of user UI to promote events, messages, or
                    advertisements.
                </p>
            </>,
        icon: uibanner_icon,
        url: uibanner
    },
    {
        id: 39,
        title: 'Wearable Scanner',
        subtitle:
            <>
                <p>
                    Allow users wearing a specific wearable to access your apartment.
                </p>
                <p>
                    Great for themed parties.
                </p>
            </>,
        icon: wearablescanner_icon,
        url: wearablescanner
    }
]
