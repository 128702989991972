import React, {useState} from "react";
import Style from "./Nav.module.css";

const CustomNav = ({isOpen, toggleMenu, text, style}) => {
    const [dropdown, setDropdown] = useState(false);

    const clickDropdown = () => {
        setDropdown(!dropdown)
    }

    return (
        <div className={Style.nav__box} onClick={isOpen? toggleMenu : null}>
            <div onClick={clickDropdown} className={`${Style.nav} ${Style[`nav__${style}`]}`}>{text}</div>
            <div onClick={clickDropdown} className={`${Style.button__nav} ${dropdown ? Style.button__false : ''}`}>
                <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M4.50498 0.354807L0.205273 4.65466C-0.0683823 4.92818 -0.0683823 5.37165 0.205273 5.64504C0.478683 5.91845 0.92213 5.91845 1.19552 5.64504L5.0001 1.84034L8.80455 5.64493C9.07808 5.91834 9.52148 5.91834 9.79489 5.64493C10.0684 5.37152 10.0684 4.92807 9.79489 4.65455L5.49512 0.354696C5.35834 0.21799 5.17928 0.149715 5.00012 0.149715C4.82088 0.149715 4.64168 0.218123 4.50498 0.354807Z"
                        fill="white"/>
                </svg>
            </div>
                {
                    dropdown ?  <div className={Style.box__link}>
                        <a href={'https://discord.gg/M239p8VNau'}  rel="noreferrer" target={"_blank"} className={Style.link}>Discord</a>
                        <a href={'https://twitter.com/residence_meta'}  rel="noreferrer" className={Style.link}  target={"_blank"} >Twitter</a>
                    </div> : ''
                }
        </div>
    )
}

export default CustomNav
