import React, {useEffect, useRef, useState} from "react";
import {motion} from "framer-motion";
import Style from './ChoiceApartment.module.css'
import elevator from '../../../images/Shop/image_elevator.webp'
import Apartment from "../Apartment/Apartment";
import {nameApartment} from "../Apartment/NameApartment";
import Preloader from "../../Preloaders/Preloaders";

const ChoiceApartment = ({isEnabled, selectedCard, selectedApartment, ownerLists, dataApartment}) => {
    const [loading, setLoading] = useState(false);
    const prevData = useRef(ownerLists);
    const Animation = {
        hidden: {
            x: 200,
            opacity: 0,
        },
        visible: {
            x: 0,
            opacity: 1,
            transition: {duration: 1}
        }
    }
    useEffect(() => {
        if (prevData.current !== ownerLists) {
            setLoading(true)
            setTimeout(() => {
                setLoading(false);
            }, 200);
        }
    }, [ownerLists])

    return (
        <motion.section
            initial={'hidden'}
            whileInView={'visible'}
            viewport={{ once: true }}
            variants={Animation}
            className={Style.apartment}>
            { loading ? <Preloader/> :
                ownerLists.map((item, index) => (
                    <div key={index} >
                        <Apartment dataApartment={dataApartment} index={index} item={item} name={nameApartment(selectedCard - 1, index)} selectedApartment={selectedApartment}/>
                    </div>
                ))
            }

            <div className={Style.apartment__container}>
                <div className={Style.center__container}>
                    <div className={Style.left__container}>
                        <div className={Style.left__container_center}>
                            <img className={Style.elevator} loading="lazy" src={elevator} alt={'image elevator'}/>
                        </div>
                    </div>
                    <div className={Style.container_center}>
                        <div className={Style.top__box_center}>
                            <div className={Style.floor__container}>
                                <div className={`${Style.center__text} ${Style.green}`}>FLOOR</div>
                                <div className={Style.floor}>{selectedCard - 1}</div>
                            </div>
                            <div className={Style.sector__container}>
                                <div className={Style.sector}>{isEnabled? 'B' : 'A'}</div>
                                <div className={`${Style.center__text} ${Style.beige}`}>sector </div>
                            </div>
                        </div>
                        <div className={Style.bottom__box}>
                            <div className={`${Style.center__text} ${Style.green}`}>legend</div>
                            <div className={`${Style.center__text} ${Style.red}`}>Occupied</div>
                        </div>
                    </div>
                    <div className={Style.right__container}>
                    </div>
                </div>
            </div>
        </motion.section>
    );
};

export default ChoiceApartment
