import {motion} from "framer-motion";
import Logo from "../Logo/Logo";
import React from "react";
import Style from './AccessFeatures.module.css'
import AccessFeaturesCards from "./AccessFeaturesCards/AccessFeaturesCards";
import PublicMode from '../../images/AccessFeatures/PublicMode.svg'
import PinCodeAccess from '../../images/AccessFeatures/PinCodeAccess.svg'
import AccessNFTs from '../../images/AccessFeatures/AccessNFTs.svg'
import AccessDesigners from '../../images/AccessFeatures/AccessDesigners.svg'
import AccessPayment from '../../images/AccessFeatures/AccessPayment.svg'
import Membership from '../../images/AccessFeatures/Membership.svg'
import AccessWearables from '../../images/AccessFeatures/AccessWearables.svg'
import LogoImg from '../../images/AccessFeatures/Logo.svg'

const AccessFeatures = () => {
    const Animation = {
        hidden: {
            x: -300,
            opacity: 0,
        },
        visible: {
            x: 0,
            opacity: 1,
            transition: {duration: 1}
        }
    }
    const Animation2 = {
        hidden: {
            x: 300,
            opacity: 0,
        },
        visible: {
            x: 0,
            opacity: 1,
            transition: {duration: 1}
        }
    }

    return (
        <motion.section className={Style.container} initial={'hidden'}
                        whileInView={'visible'} viewport={{ once: true }}
                        > 
            <Logo text={'Access Features'}/>
            <div className={Style.box}>
                <motion.div  variants={Animation} className={Style.cards_box} viewport={{ once: true }}
 >
                    <AccessFeaturesCards title={'Public Mode'} text={'Anyone can enter the apartment'}
                                         img={PublicMode}/>
                    <AccessFeaturesCards title={'Pin Code Access'}
                                         text={'Only pin code holders can enter the apartment'} img={PinCodeAccess}/>
                    <AccessFeaturesCards title={'Access by NFTs'}
                                         text={'The owner can specify addresses for up to 3 NFT(Ethereum/Polygon) addresses. Users holding these NFT items can enter the apartment'}
                                         img={AccessNFTs} style={'adaptive'}/>
                </motion.div>
                <div className={Style.logo_box}>
                    <img className={Style.logo_img} loading="lazy" src={LogoImg} alt={'logo'}/>
                </div>
                <motion.div  variants={Animation2} className={Style.cards_box} viewport={{ once: true }}
>
                    <AccessFeaturesCards title={'Access for Interior Designers'}
                                         text={'The owner can specify an address with access to all apartment design functions'}
                                         img={AccessDesigners}/>
                    <AccessFeaturesCards title={'Access for Payment'}
                                         text={'The owner can set up payment for entry to the apartment'}
                                         img={AccessPayment}/>
                    <AccessFeaturesCards title={'Membership'}
                                         text={'The owner can sell permanent access to their space'} img={Membership}
                                         style={'adaptive'}/>
                </motion.div>
                <div className={Style.center_box}>
                    <AccessFeaturesCards title={'Access by Wearables'}
                                         text={'The owner can specify addresses for up to 3 Wearables items. Users wearing these Wearables items can enter the apartment'}
                                         img={AccessWearables}
                                         style={'craft'}/>
                </div>
            </div>
        </motion.section>
    )
}

export default AccessFeatures
