import studio from "../../../images/Shop/studio.webp";
import studio1 from "../../../images/Shop/studio_1.webp";
import studio2 from "../../../images/Shop/studio_2.webp";
import studio3 from "../../../images/Shop/studio_3.webp";
import BHK from "../../../images/Shop/1BHK.webp";
import BHK1 from "../../../images/Shop/1BHK_1.webp";
import BHK2 from "../../../images/Shop/1BHK_2.webp";
import BHK3 from "../../../images/Shop/1BHK_3.webp";
import BHK21 from "../../../images/Shop/2BHK.webp";
import BHK22 from "../../../images/Shop/2BHK_1.webp";
import BHK23 from "../../../images/Shop/2BHK_2.webp";
import BHK24 from "../../../images/Shop/2BHK_3.webp";
import Penthouse from "../../../images/Shop/Penthause.webp";
import Penthouse1 from "../../../images/Shop/Penthouse1.webp";
import Penthouse2 from "../../../images/Shop/Penthouse2.webp";
import Penthouse3 from "../../../images/Shop/Penthouse3.webp";

export const imagesStudio = [
    {
        id: 1,
        url: studio
    },
    {
        id: 2,
        url: studio1
    },
    {
        id: 3,
        url: studio2
    },
    {
        id: 4,
        url: studio3
    }]
export const images1BHK = [
    {
        id: 1,
        url: BHK
    },
    {
        id: 2,
        url: BHK1
    },
    {
        id: 3,
        url: BHK2
    },
    {
        id: 4,
        url: BHK3
    }]

export const images2BHK = [
    {
        id: 1,
        url: BHK21
    },
    {
        id: 2,
        url: BHK22
    },
    {
        id: 3,
        url: BHK23
    },
    {
        id: 4,
        url: BHK24
    }]

export const imagesPenthouse = [
    {
        id: 1,
        url: Penthouse
    },
    {
        id: 2,
        url: Penthouse1
    },
    {
        id: 3,
        url: Penthouse2
    },
    {
        id: 4,
        url: Penthouse3
    }]
